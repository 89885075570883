import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import useSWR, { useSWRConfig } from "swr";
import { displayError } from "utils";

type RfqHistoryItemApi = {
    id: number;
    counterpartyId: number;
    counterpartyName: string;
    instrumentName: string;
    side: "BUY" | "SELL";
    price: bigint | number;
    size: bigint | number;
    status: "CREATED" | "CANCELED" | "COMMITTED" | "EXPIRED";
    date: number;
    tradeId: number;
    clientDelta: bigint | number;
};

export type RfqHistoryItem = RfqHistoryItemApi & {
    price: bigint;
    size: bigint;
    clientDelta: bigint;
};

const prepareRfqHistoryItem = (apiItem: RfqHistoryItemApi): RfqHistoryItem => ({
    ...apiItem,
    date: apiItem.date * 1000,
    price: BigInt(apiItem.price ?? 0),
    size: BigInt(apiItem.size),
    clientDelta: BigInt(apiItem.clientDelta ?? 0),
});

type RfqHistoryApiResponse = {
    content: RfqHistoryItemApi[];
    pageable: {
        pageNumber: number;
        pageSize: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        offset: 0;
        unpaged: boolean;
        paged: boolean;
    };
    last: boolean;
    totalElements: number;
    totalPages: number;
    size: number;
    number: number;
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
    first: boolean;
    numberOfElements: number;
    empty: boolean;
};

const LAST_RFQ_SWR_KEY = "lastRfqTrades";

export const useRefreshLastRfqTrades = () => {
    const { mutate } = useSWRConfig();

    return () => {
        mutate((key) => Array.isArray(key) && key[2] === LAST_RFQ_SWR_KEY);
    };
};

const fetcher = ([selectedStatuses, filter]: [
    selectedStatuses: RfqHistoryItem["status"][],
    filter?: "EXTERNAL" | "SUBACCOUNTS",
]) => post("rfq/getSessions", { pageSize: 20, pageNumber: 0, filter, statuses: selectedStatuses });

export const useLastRfqTrades = ({
    selectedStatuses,
}: {
    selectedStatuses: RfqHistoryItem["status"][];
}) => {
    const isMasterUser = useIsPrimeBrokerUser();
    const viewType = usePrimeBrokerViewType();

    let filter: "EXTERNAL" | "SUBACCOUNTS" | undefined = undefined;
    if (isMasterUser) {
        filter = viewType === "counterparties" ? "EXTERNAL" : "SUBACCOUNTS";
    }
    const { data, isLoading, error, mutate } = useSWR<RfqHistoryApiResponse>(
        [selectedStatuses, filter, LAST_RFQ_SWR_KEY],
        fetcher,
        {
            onError: displayError,
        },
    );

    return {
        lastRfq: (data?.content ?? (EMPTY_ARRAY as RfqHistoryItemApi[])).map(prepareRfqHistoryItem),
        mutate,
        isLoading,
        error,
    };
};

export const getRfqTradesForExport = async ({
    from,
    to,
}: {
    from: number | undefined;
    to: number | undefined;
}) => {
    const data = await post("rfq/getSessions", {
        pageNumber: 0,
        from,
        to,
        statuses: ["COMMITTED"],
    });
    const rfqDeals: RfqHistoryItem[] = (data?.content ?? (EMPTY_ARRAY as RfqHistoryItemApi[])).map(
        prepareRfqHistoryItem,
    );

    return rfqDeals;
};

const rfqHistoricalTradesFetcher = ([pageNumber, from, to, instruments, filter, counterpartyIds]: [
    pageNumber: number,
    from: number,
    to: number,
    instrument: string,
    filter: "EXTERNAL" | "SUBACCOUNTS",
    counterpartyIds: number[],
]) =>
    post("rfq/getSessions", {
        pageSize: 250,
        pageNumber: pageNumber - 1,
        from,
        to,
        instruments,
        filter,
        counterpartyIds,
        statuses: ["COMMITTED"],
    });

export const useRfqHistoricalTrades = ({
    pageNumber,
    from,
    to,
    instruments,
    counterpartyIds,
}: Partial<{
    pageNumber: number;
    from: number;
    to: number;
    instruments: string[];
    counterpartyIds: number[];
}>) => {
    const isMasterUser = useIsPrimeBrokerUser();
    const viewType = usePrimeBrokerViewType();

    let filter: "EXTERNAL" | "SUBACCOUNTS" | undefined = undefined;
    if (isMasterUser) {
        filter = viewType === "counterparties" ? "EXTERNAL" : "SUBACCOUNTS";
    }

    const { data, isLoading, error, mutate, isValidating } = useSWR<RfqHistoryApiResponse>(
        [pageNumber, from, to, instruments, filter, counterpartyIds],
        rfqHistoricalTradesFetcher,
        {
            onError: displayError,
        },
    );

    return {
        rfqDeals: (data?.content ?? (EMPTY_ARRAY as RfqHistoryItemApi[])).map(
            prepareRfqHistoryItem,
        ),
        totalPages: data?.totalPages ?? 0,
        totalElements: data?.totalElements ?? 0,
        isLoading,
        error,
        mutate,
        isValidating,
    };
};
