import { SEARCH_PARAMS } from "const";
import { useFilterState } from "hooks/useFilterState";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

const PAGE_FILTER_KEY = "trading-firm-book";

export type AuthorizedClientId = number | null;

export const useAuthorizedClientId = () => {
    const history = useHistory();
    const [state, setState, deleteState] = useFilterState<string>(
        PAGE_FILTER_KEY,
        SEARCH_PARAMS.authorizedClientId,
        "",
    );

    const setAuthorizedClientId = useCallback(
        (newAuthorizedClientId: number) => {
            const queryParams = new URLSearchParams(history.location.search);
            for (let param of queryParams.keys()) {
                if (param.includes(state)) {
                    queryParams.delete(param);
                }
            }

            history.push({ search: queryParams.toString() });

            setState(String(newAuthorizedClientId));
        },
        [setState, history, state],
    );

    return useMemo(
        () => ({
            authorizedClientId: (/^\d+$/.test(state) ? Number(state) : null) as AuthorizedClientId,
            setAuthorizedClientId,
            deleteAuthorizedClientId: deleteState,
        }),
        [state, setState, deleteState],
    );
};
