import { VStack } from "@fm-frontend/uikit";
import { EmDash } from "const";
import { startOfDay, subMonths } from "date-fns";
import {
    getRfqTradesForExport,
    useRfqHistoricalTrades,
} from "feature/rfq/apiHooks/useLastRfqTrades";
import { useCounterparties, useInstruments } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { LS_VARIABLES, useLSState } from "hooks/useLSState";
import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useUserType } from "store/hooks";
import styled from "styled-components";
import { fmt } from "utils/format";
import { GroupingOrder, Header, Range } from "./Header";
import { HistoryType, useCounterpartiesState, useInstrumentsState, useRangeState } from "./hooks";
import { RfqContent } from "./RfqContent";
import { makeRfqExportColumns } from "./rfqExportHeaders";

const getDefaultRange = () => ({
    startDate: startOfDay(subMonths(new Date(), 1)),
    endDate: new Date(),
});

export const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const RfqHistory = ({
    setHistoryType,
}: {
    setHistoryType: (value: HistoryType, replace?: boolean) => void;
}) => {
    const { cpIds: counterparties, isLoading: isCounterpartiesLoading } = useCounterparties();
    const { instruments: instrumentList, isLoading: isInstrumentsLoading } = useInstruments();

    const instruments = useMemo(
        () => instrumentList.map((instrument) => instrument.instrumentName),
        [instrumentList],
    );

    const [currentPage, setCurrentPage] = useState(1);
    const [range, setRange, clearRange] = useRangeState(getDefaultRange());
    const [selectedCounterparties, setSelectedCounterparties, clearSelectedCounterparties] =
        useCounterpartiesState([], isCounterpartiesLoading ? undefined : counterparties);
    const [selectedInstruments, setSelectedInstruments, clearSelectedInstruments] =
        useInstrumentsState([], isInstrumentsLoading ? undefined : instruments);

    const onHistoryTypeChange = (type: HistoryType) => {
        setCurrentPage(1);
        clearRange(true);
        clearSelectedCounterparties(true);
        clearSelectedInstruments(true);

        setHistoryType(type);
    };

    const [groupingOrder, setGroupingOrder] = useLSState<GroupingOrder>(
        LS_VARIABLES.TRADES_GROUPED_BY,
        GroupingOrder.ByOrder,
    );

    const { rfqDeals, isLoading, isValidating, mutate, totalPages, totalElements } =
        useRfqHistoricalTrades({
            from: range.startDate ? range.startDate?.getTime() : undefined,
            to: range.endDate ? range.endDate?.getTime() : undefined,
            counterpartyIds: selectedCounterparties,
            instruments: selectedInstruments,
            pageNumber: currentPage,
        });
    const hasPrevPage = currentPage > 1;
    const hasNextPage = currentPage < totalPages;

    const { getCpName } = useCpInfoHelpers();
    const userType = useUserType();
    const getExportData = useCallback(
        async ({ startDate, endDate }: Range) => {
            const exportColumns = makeRfqExportColumns({ userType });
            let exportData: Record<string, any>[] = [];

            const preparedPageData = await getRfqTradesForExport({
                from: startDate ? startDate?.getTime() / 1000 : undefined,
                to: endDate ? endDate?.getTime() / 1000 : undefined,
            });
            const exportPageData = preparedPageData.map((preparedDeal) =>
                exportColumns.reduce((row, column) => {
                    const value = column.accessor({
                        ...preparedDeal,
                        counterpartyName: getCpName(preparedDeal.counterpartyId, "full"),
                    });
                    const formattedValue = column.format
                        ? fmt(value ?? "", column.format).copyableValue
                        : value;

                    row[column.Header] = formattedValue === EmDash ? "-" : formattedValue;
                    return row;
                }, {} as Record<string, any>),
            );
            exportData = exportData.concat(exportPageData);

            return exportData;
        },
        [userType],
    );

    const handleRangeChange = ({ startDate, endDate }: Range) => {
        setCurrentPage(1);
        setRange({ startDate, endDate });
    };
    const handleSelectedCounterpartiesChange = (newCounterparties: number[]) => {
        if (!isEqual(selectedCounterparties, newCounterparties)) {
            setCurrentPage(1);
            setSelectedCounterparties(newCounterparties);
        }
    };
    const handleSelectedInstrumentsChange = (newInstruments: string[]) => {
        if (!isEqual(selectedInstruments, newInstruments)) {
            setCurrentPage(1);
            setSelectedInstruments(newInstruments);
        }
    };
    const handleRangeReset = () => {
        setCurrentPage(1);
        setRange(getDefaultRange());
    };
    const handleFilterReset = () => {
        setCurrentPage(1);
        setSelectedCounterparties([]);
        setSelectedInstruments([]);
    };
    const handlePrevClick = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    const handleNextClick = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <VStack margin={[0, 12, 0, 12]}>
            <CardContainer asCard>
                <Header
                    key={`${isCounterpartiesLoading}_${isInstrumentsLoading}`}
                    range={range}
                    groupingOrder={groupingOrder}
                    counterparties={counterparties}
                    instruments={instruments}
                    historyType={HistoryType.RFQ}
                    onHistoryTypeChange={onHistoryTypeChange}
                    selectedCounterparties={selectedCounterparties}
                    selectedInstruments={selectedInstruments}
                    isRefreshing={isValidating}
                    getExportData={getExportData}
                    onRangeChange={handleRangeChange}
                    onRangeReset={handleRangeReset}
                    onFilterReset={handleFilterReset}
                    onGroupingOrderChange={setGroupingOrder}
                    onSelectedCounterpartiesChange={handleSelectedCounterpartiesChange}
                    onSelectedInstrumentsChange={handleSelectedInstrumentsChange}
                    onRefresh={mutate}
                />
                <RfqContent
                    data={rfqDeals}
                    pageItemsCount={rfqDeals.length}
                    allItemsCount={totalElements}
                    isLoading={isLoading}
                    hasPrevPage={hasPrevPage}
                    hasNextPage={hasNextPage}
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                />
            </CardContainer>
        </VStack>
    );
};
