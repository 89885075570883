import { useAuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useSelector } from "hooks/useSelector";
import { useMemo } from "react";
import { useIsPrimeBrokerUser } from "store/hooks";

export const useFavoriteInstruments = () => {
    const favoriteInstrumentsIds = useSelector((state) => state.settings.favoriteInstruments);
    const clientId = useSelector((state) => state.authentication.clientData?.clientId);
    const { authorizedClientId } = useAuthorizedClientId();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();

    const favoriteInstruments = useMemo(() => {
        if (isPrimeBrokerUser && authorizedClientId) {
            return favoriteInstrumentsIds[authorizedClientId]?.favoriteInstruments;
        }

        if (clientId) {
            return favoriteInstrumentsIds[clientId]?.favoriteInstruments;
        }

        return [];
    }, [isPrimeBrokerUser, authorizedClientId, favoriteInstrumentsIds, clientId]);

    return {
        favoriteInstruments: favoriteInstruments ?? [],
        allFavoriteInstruments: favoriteInstrumentsIds ?? {},
    };
};
