import { noop, SIZE64 } from "@fm-frontend/utils";
import { wsUrl } from "const/env";
import { useSaveSuccessNotification } from "feature/addresses/hooks";
import { useCallback, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { WebSocketService } from "services/WebSocketService";
import { displayError } from "utils";
import { Quote } from "../components/RfqRequestModal/QuotesPanel/QuotesList";

export type RfqRequestSide = "BUY" | "SELL" | "BOTH";
export type QuoteSide = "BUY" | "SELL";

enum EventIn {
    Connected = "CONNECTED",
    Authorized = "AUTHORIZED",
    Created = "CREATED",
    Quote = "QUOTE",
    Error = "ERROR",
    Canceled = "CANCELED",
    Committed = "COMMITTED",
}
type QuoteStatus = "SUCCESS" | "ERROR" | "REJECT";
type CommitStatus = "SUCCESS" | "ERROR" | "REJECT";
type MessageIn =
    | {
          event: EventIn.Connected | EventIn.Authorized;
      }
    | {
          event: EventIn.Created | EventIn.Canceled;
          reqId: number;
      }
    | {
          event: EventIn.Error;
          reqId: number;
          error: number;
          errors: string;
      }
    | {
          event: EventIn.Quote;
          reqId: number;
          status: QuoteStatus;
          error?: string;
          expiresAt: number;
          providerId: number;
          quotes: {
              side: QuoteSide;
              price: number | bigint;
          }[];
      }
    | {
          event: EventIn.Committed;
          reqId: number;
          status: CommitStatus;
          error?: string;
      };

enum EventOut {
    Authenticate = "AUTH",
    Create = "CREATE",
    Commit = "COMMIT",
    Cancel = "CANCEL",
}
type MessageOut =
    | {
          event: EventOut.Create;
          reqId: number;
          instrument: string;
          providers: number[];
          side: RfqRequestSide;
          size: number;
      }
    | {
          event: EventOut.Create;
          reqId: number;
          instrument: string;
          providers: number[];
          side: RfqRequestSide;
          volume: number;
      }
    | {
          event: EventOut.Commit;
          reqId: number;
          providerId: number;
          side: QuoteSide;
      }
    | {
          event: EventOut.Cancel;
          reqId: number;
      }
    | {
          event: EventOut.Authenticate;
          content: string;
          key: string;
          signature: string;
      };

const rfqWebSocketService = new WebSocketService<MessageIn, MessageOut>(
    `${wsUrl}/ws/rfq`,
    ({ event }) => event === EventIn.Connected,
    ({ event }) => event === EventIn.Authorized,
);

export const useConnectToRfqWs = () => {
    useLayoutEffect(() => {
        return rfqWebSocketService.subscribe(noop);
    }, []);
};

const useLastMessageOfRfqWs = () => {
    const [lastWsMessage, setLastWsMessage] = useState<MessageIn | null>(null);

    useLayoutEffect(() => {
        return rfqWebSocketService.subscribe(({ lastMessage }) => setLastWsMessage(lastMessage));
    }, []);

    return lastWsMessage;
};

type CommitSubscriber = (status: CommitStatus, error?: string) => void;
const createCommitResponseEventEmmiter = () => {
    let subscriber: null | CommitSubscriber = null;
    return {
        subscribe(callback: CommitSubscriber) {
            subscriber = callback;
        },
        unsibscribe() {
            subscriber = null;
        },
        trigger(status: CommitStatus, error?: string) {
            subscriber?.(status, error);
        },
    };
};

let rId = 1;

const CANCEL_RFQ_SESSION_ERROR_CODE = 9;

export const useSendRfqRequest = (
    instrumentName: string,
    providersIds: number[],
    requestSide: RfqRequestSide,
    amount: bigint,
) => {
    const showSuccessNotification = useSaveSuccessNotification();
    const [reqId, setNextReqId] = useReducer(
        () => rId++,
        null,
        () => rId++,
    );
    const commitResponseRef = useRef(createCommitResponseEventEmmiter());
    const [quotes, setQuotes] = useState<Quote[]>([]);
    const [error, setError] = useState<{ errorCode: number; errorMsg?: string } | null>(null);

    const lastMessage = useLastMessageOfRfqWs();

    useEffect(() => {
        setQuotes([]);
        rfqWebSocketService.sendMessage({
            event: EventOut.Create,
            reqId: reqId,
            instrument: instrumentName,
            providers: providersIds,
            side: requestSide,
            size: Number(amount),
        });
    }, [reqId]);

    useEffect(() => {
        if (lastMessage?.event === EventIn.Error) {
            // ignore error about wrong reqId for cancelation (it does not make sense for UI)
            if (lastMessage.error === CANCEL_RFQ_SESSION_ERROR_CODE) {
                return;
            }
            setError({
                errorCode: lastMessage.error,
                errorMsg: lastMessage.errors,
            });
            return;
        }

        if (!lastMessage || !("reqId" in lastMessage) || lastMessage.reqId !== reqId) {
            return;
        }

        switch (lastMessage.event) {
            case EventIn.Quote:
                const { quotes: newQuotes, providerId, status, error, expiresAt } = lastMessage;
                switch (status) {
                    case "SUCCESS":
                        setQuotes((prevQuotes) => [
                            ...prevQuotes,
                            ...newQuotes.map(({ price }) => ({
                                providerId,
                                price: BigInt(price),
                                expiresAt,
                            })),
                        ]);
                        return;
                    case "REJECT":
                        setQuotes((prevQuotes) => [
                            ...prevQuotes,
                            {
                                providerId,
                                rejectReason: "Rejected trade",
                            },
                        ]);
                        return;
                    case "ERROR":
                        setQuotes((prevQuotes) => [
                            ...prevQuotes,
                            {
                                providerId,
                                error: error || "Trade error",
                            },
                        ]);
                        return;
                }
            case EventIn.Committed:
                commitResponseRef.current.trigger(lastMessage.status, lastMessage.error);
                return;
        }
    }, [lastMessage, reqId]);

    const isCommittingRef = useRef(false);
    const commitQuote = useCallback(
        (providerId: number, side: QuoteSide) => {
            const tradingQuote = quotes.find(({ providerId: pId }) => pId === providerId);
            if (isCommittingRef.current || !tradingQuote || !("price" in tradingQuote)) {
                return Promise.reject();
            }
            isCommittingRef.current = true;
            rfqWebSocketService.sendMessage({
                event: EventOut.Commit,
                reqId: reqId,
                providerId,
                side,
            });

            return new Promise<void>((resolve, reject) => {
                commitResponseRef.current.subscribe((status, error) => {
                    if (status === "SUCCESS") {
                        showSuccessNotification(
                            `Trade ${instrumentName} ${requestSide} ${SIZE64.toSize64FormattedStr(
                                amount,
                            )} for ${SIZE64.toSize64FormattedStr(tradingQuote.price)} was done`,
                        );
                        resolve();
                        return;
                    }
                    displayError(
                        `Trade ${instrumentName} ${requestSide} ${SIZE64.toSize64FormattedStr(
                            amount,
                        )} for ${SIZE64.toSize64FormattedStr(tradingQuote.price ?? 0n)} was ${
                            status === "ERROR" ? `FAILED (${error})` : "REJECTED"
                        }`,
                    );
                    setQuotes((prevQuotes) =>
                        prevQuotes.map((quote) => {
                            if (quote.providerId !== providerId) {
                                return quote;
                            }
                            if (status === "ERROR") {
                                return {
                                    providerId,
                                    error: error || "Unknown error",
                                };
                            }
                            return {
                                providerId,
                                rejectReason: "Rejected by provider",
                            };
                        }),
                    );
                    reject();
                });
            }).finally(() => {
                commitResponseRef.current.unsibscribe();
                isCommittingRef.current = false;
            });
        },
        [reqId, quotes],
    );

    const cancelRequest = useCallback(() => {
        rfqWebSocketService.sendMessage({
            event: EventOut.Cancel,
            reqId: reqId,
        });
    }, [reqId]);

    return { quotes, commitQuote, refreshQuotes: setNextReqId, cancelRequest, error };
};
