import {
    BasicButton,
    ClientId,
    getMatch,
    getMatchStartsWith,
    H2,
    HStack,
    Modal,
    ShadowCard, Tooltip,
    VStack
} from "@fm-frontend/uikit";
import { BITMASK_UTILS, getEnv, useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { post } from "api";
import { CLIENT_TYPE_TITLES } from "const";
import { FIXED_MODAL_STYLE } from "const/modal";
import { createNotification, setConfirmModalContent } from "feature/app/store";
import { useFaqLinks } from "hooks/useFaqLinks";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CpStatus } from "store/counterpartiesSlice";
import {
    useIsMakerUser,
    useIsPrimeBrokerUser,
    useIsSubaccountUser,
    usePrimeBrokerViewType,
    useUsdPrices,
} from "store/hooks";
import { clearPreopenedCpIdLimit, getPreopenedCpIdLimit } from "store/preopenedCpIdLimit";
import { Spacer } from "style";
import styled from "styled-components";
import { CounterpartyLimitModal } from "../counterpartyLimitModal";
import {
    COUNTERPARTY_LIMIT_MODAL_KEY,
    TRADING_LIMITS_TAB,
    URL_DELETE_CLIMIT,
} from "../counterpartyLimitModal/utils";
import { LimitProps, LimitType } from "../types";
import {
    CounterpartyStatus,
    CounterpartyStatusType,
    parseLimit,
    shouldUseUserGrossLimit,
} from "../utils";
import { CounterpartyIndicator } from "./counterpartyIndicator";
import { IndicatorHint } from "./indicatorHint/IndicatorHint";
import * as Styled from "./styled";
import {
    getAppliedLimit,
    getCpLimitIndicatorData,
    getEquityIndicatorData,
    getGrossFreeIndicatorData,
    getGrossLimitIndicatorData,
    getInitialMarginLimitIndicatorData,
    getMaintenanceMarginLimitIndicatorData,
    getRestrictedTradingLimitIndicatorData,
} from "./utils";
import { CounterpartyHints } from "./CounterpartyHints";
import { Menu } from "./Menu";
import { MenuItem } from "./Menu/Item";

const Header = styled(Styled.Header)`
    padding: 12px 12px 10px 12px;
`;

const HeaderMenu = styled(Menu)`
    padding: 0 12px 8px 8px;
`;

const Body = styled(Styled.Body)`
    gap: 0;
    padding: 0;

    > * {
        border-top: 1px solid ${(p) => p.theme.colors.ui12};
    }

    > :not(:last-child) {
        border-right: 1px solid ${(p) => p.theme.colors.ui12};

        @media (max-width: 1358px) {
            border-right: none;
        }
    }
`;

const EditFormHead = styled(HStack)`
    white-space: nowrap;
    gap: 8px;
    padding: 12px;
`;

const EditFormTitle = styled(HStack)`
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    align-items: baseline;
    gap: 8px;

    ${H2} {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const EditButton = styled(BasicButton).attrs({ size: "small" })`

`;


const ShadowContainer = styled(ShadowCard)<{ isDefault?: boolean }>`
    &:hover {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        box-shadow: 0 0 0 1px ${(p) => p.theme.colors.ui12} inset, 0 4px 16px 0 ${(p) => p.theme.colors.ui8};
    }

    &:hover:not(:has(${MenuItem}:hover)) {
        ${EditButton} {
            background-color: ${(p) => p.theme.colors.ui12};
        }
    }
`;

const defaultStatusObj: CpStatus = {
    status: CounterpartyStatusType.NORMAL,
    grossStatus: CounterpartyStatusType.NORMAL,
    equityStatus: CounterpartyStatusType.NORMAL,
} as const;

const marginRequirementOffIndicator = {
    title: "Margin requirement",
    value: "Off",
};

const { HRP_MASTER_ID, HRP_CLEARING_TIME } = getEnv();
const ClearingTime = styled.div`
    width: 100%;
    text-align: right;
    color: ${(p) => p.theme.colors.ui72};
`;
const hrpClearingTime = <ClearingTime>🗓️ Clearing at {HRP_CLEARING_TIME}</ClearingTime>;

const { isBitKeyApplied } = BITMASK_UTILS;

export const Counterparty: React.FC<LimitProps> = (props) => {
    const faqLinks = useFaqLinks();
    const { cpTitle, extendedLimit, cpId, cpStatus, searchQuery = "" } = props;
    const [isOpened, setIsOpened] = useState(getPreopenedCpIdLimit() === cpId);
    const [defaultTab, setDefaultTab] = useState(TRADING_LIMITS_TAB);
    const isSubaccountUser = useIsSubaccountUser();
    const isMakerUser = useIsMakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    useEffect(() => {
        clearPreopenedCpIdLimit();
    }, []);

    const { priceObj } = useUsdPrices();

    const { limit, cpType: limitCpType } = extendedLimit;
    const shouldTakeUserGrossLimit = shouldUseUserGrossLimit(limitCpType, isMakerUser);

    const { counterpartyId, maintenanceMargin, restrictedTrading, initialMargin } = parseLimit(limit);

    const { status, grossStatus, equityStatus } = cpStatus ?? defaultStatusObj;

    const { appliedLimitType, appliedLimit } = useMemo(() => getAppliedLimit(limit, priceObj), [limit, priceObj]);

    const grossFreeIndicator = useMemo(
        () => getGrossFreeIndicatorData(limit, appliedLimit, isSubaccountUser),
        [limit, appliedLimit, isSubaccountUser],
    );

    const grossLimitIndicator = useMemo(
        () => getGrossLimitIndicatorData(limit, appliedLimitType),
        [limit, appliedLimitType],
    );

    const cpLimitIndicator = useMemo(
        () => getCpLimitIndicatorData(limit, appliedLimitType, isSubaccountUser, cpTitle),
        [limit, appliedLimitType, isSubaccountUser, cpTitle],
    );

    const equityIndicator = useMemo(
        () => getEquityIndicatorData(limit, shouldTakeUserGrossLimit, limitCpType, cpTitle),
        [limit, shouldTakeUserGrossLimit, limitCpType, cpTitle],
    );

    const maintenanceMarginLimitIndicator = useMemo(
        () => getMaintenanceMarginLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const restrictedTradingLimitIndicator = useMemo(
        () => getRestrictedTradingLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const initialMarginLimitIndicator = useMemo(
        () => getInitialMarginLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const isMarginRequirementOn = Boolean(maintenanceMargin && restrictedTrading && initialMargin);

    // CounterpartyLimitModal helpers
    const dispatch = useDispatch();
    const [limitDeleting, setLimitDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState<string>();
    const closeModal = () => {
        setDeleteError(undefined);
        setIsOpened(false);
    };
    const shouldConfirmClose = useFormShouldCloseConfirm(COUNTERPARTY_LIMIT_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);
    const handleLimitDelete = async () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete your trading activity with this client?",
                onConfirm: async () => {
                    try {
                        setLimitDeleting(true);
                        await post(URL_DELETE_CLIMIT, {
                            counterpartyId: counterpartyId,
                        });

                        dispatch(
                            createNotification({
                                type: "success",
                                content: "Limit has been deleted",
                            }),
                        );
                        closeModal();
                    } catch (e) {
                        setDeleteError(String(e));
                    } finally {
                        setLimitDeleting(false);
                    }
                },
                description: "",
                confirmButtonTitle: "Delete",
            }),
        );
    };

    const cpLimitHidden = isPrimeBrokerUser && primeBrokerViewType === "subaccounts";

    const handleConfigureClick = () => {
        if (!isSubaccountUser) {
            setDefaultTab(TRADING_LIMITS_TAB);
            setIsOpened(true);
        }
    };

    const type = Number(cpId) === HRP_MASTER_ID ? "Clearing broker" : CLIENT_TYPE_TITLES[limitCpType];

    return (
        <ShadowContainer isDefault>
            <Styled.Container onClick={handleConfigureClick}>
                <Header>
                    <Styled.Title>
                        <Styled.TitleBody>
                            <Styled.TitleHeader>
                                <H2>{getMatch(cpTitle, searchQuery) ?? cpTitle}</H2>
                                <ClientId
                                    id={<>{type} {getMatchStartsWith(String(counterpartyId), searchQuery) ?? counterpartyId}</>}
                                />
                            </Styled.TitleHeader>
                        </Styled.TitleBody>
                    </Styled.Title>
                    <Styled.Actions>
                        {(isBitKeyApplied(CounterpartyStatusType.LOW_GROSS_FREE, status) || isBitKeyApplied(CounterpartyStatusType.LIQUIDATION, status)) ? (
                            <Tooltip positions={["left"]} align={"center"} isHoveredContent content={<>
                                <IndicatorHint status={status} statusMask={CounterpartyStatusType.LOW_GROSS_FREE} />
                                <IndicatorHint status={status} statusMask={CounterpartyStatusType.LIQUIDATION} />
                            </>}>
                                <Styled.Badge status={status}>{status ? CounterpartyStatus[status].title : ""}</Styled.Badge>
                            </Tooltip>
                        ) : (
                            <Styled.Badge status={status}>{status ? CounterpartyStatus[status].title : ""}</Styled.Badge>
                        )}

                        {!isSubaccountUser && (
                            <EditButton onClick={handleConfigureClick}>
                                Edit
                            </EditButton>
                        )}
                    </Styled.Actions>
                </Header>
                <HeaderMenu limit={limit} limitCpType={limitCpType} />
                <Styled.ScrollContainer>
                    <Body>
                        <Styled.HintWrapper>
                            <Styled.IndicatorContainer status={status} subStatus={grossStatus}>
                                <Styled.IndicatorWrapper>
                                    <CounterpartyIndicator
                                        status={status}
                                        subStatus={grossStatus}
                                        indicatorData={grossFreeIndicator}
                                        mainIndicator
                                        highlighted
                                    />
                                </Styled.IndicatorWrapper>
                                <Styled.IndicatorWrapper>
                                    {!isSubaccountUser && (
                                        <CounterpartyIndicator
                                            indicatorData={grossLimitIndicator}
                                            highlighted={appliedLimitType === LimitType.UserLimit}
                                        />
                                    )}
                                    {!cpLimitHidden && (
                                        <CounterpartyIndicator
                                            indicatorData={cpLimitIndicator}
                                            highlighted={appliedLimitType === LimitType.MutualLimit}
                                        />
                                    )}
                                </Styled.IndicatorWrapper>
                            </Styled.IndicatorContainer>
                        </Styled.HintWrapper>
                        <Styled.HintWrapper>
                            <Styled.IndicatorContainer status={status} subStatus={equityStatus}>
                                <Styled.IndicatorWrapper>
                                    <CounterpartyIndicator
                                        status={status}
                                        subStatus={equityStatus}
                                        indicatorData={equityIndicator}
                                        mainIndicator={true}
                                        highlighted={true}
                                    />
                                    {HRP_MASTER_ID === Number(cpId) && hrpClearingTime}
                                </Styled.IndicatorWrapper>
                                <Styled.IndicatorWrapper>
                                    {isMarginRequirementOn ? (
                                        <>
                                            <CounterpartyIndicator
                                                indicatorData={
                                                    isMakerUser || isPrimeBrokerUser
                                                        ? maintenanceMarginLimitIndicator
                                                        : initialMarginLimitIndicator
                                                }
                                            />
                                            <CounterpartyIndicator indicatorData={restrictedTradingLimitIndicator} />
                                        </>
                                    ) : (
                                        <CounterpartyIndicator
                                            indicatorData={marginRequirementOffIndicator}
                                            highlighted={false}
                                        />
                                    )}
                                </Styled.IndicatorWrapper>
                            </Styled.IndicatorContainer>
                        </Styled.HintWrapper>
                    </Body>
                </Styled.ScrollContainer>
            </Styled.Container>
            <Modal
                isOpen={isOpened}
                onClose={closeModalWithConfirm}
                additionalActions={{
                    helpButtonUrlOrClickHandler: faqLinks?.faqRiskManagement,
                }}
                style={FIXED_MODAL_STYLE}
            >
                <HStack asCard>
                    <VStack width="360px">
                        <EditFormHead>
                            <EditFormTitle>
                                <H2>{cpTitle}</H2>
                                <ClientId id={`${type} ${cpId}`} />
                                <Spacer />
                                {
                                    <Styled.Badge status={status}>
                                        {status ? CounterpartyStatus[status].title : ""}
                                    </Styled.Badge>
                                }
                            </EditFormTitle>
                        </EditFormHead>
                        <VStack padding={12} paddingTop={0}>
                            <CounterpartyLimitModal
                                defaultTab={defaultTab}
                                status={status}
                                title={cpTitle}
                                limit={limit}
                                cpData={{ cpId: counterpartyId, cpType: limitCpType, cpName: cpTitle }}
                                deleting={limitDeleting}
                                error={deleteError}
                                onSubmit={closeModal}
                                onClose={closeModalWithConfirm}
                                onDelete={handleLimitDelete}
                            />
                        </VStack>
                    </VStack>
                    <CounterpartyHints
                        cpId={Number(cpId)}
                        cpType={limitCpType}
                        cpName={cpTitle}
                        limit={limit}
                    />
                </HStack>
            </Modal>
        </ShadowContainer>
    );
};
