import { Flex, VStack } from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useCallback, useMemo } from "react";
import { GroupBy, GroupedPositionsData } from "store/usePositionsGrouped";
import styled from "styled-components";
import { AuthorizedPositionsTable } from "./AuthorizedPositionsTable";
import { PositionsTableRowData } from "./types";
import { isGroupedByAssetExpandableRow, isGroupedByCpExpandableRow } from "./utils";

const Container = styled(Flex)`
    overflow: auto;
    padding: 0 1px 1px 1px;
    border-radius: 16px;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const { HRP_MASTER_ID } = getEnv();

export const AuthorizedPositionsAccordionContent = ({
    groupBy,
    groupedPositionsData,
    isLoading,
}: {
    groupBy: GroupBy;
    groupedPositionsData: GroupedPositionsData;
    isLoading: boolean;
}) => {
    const { getCpName } = useCpInfoHelpers();

    const data: PositionsTableRowData[] = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return [
            ...Object.entries(groupedPositionsData)
                .map(([groupId, groupData]) => {
                    const {
                        partUSD: valueUSD,
                        partPlannedUSD: plannedValueUSD,
                        subitems,
                    } = groupData;

                    const subrows = Object.values(subitems) as any[];
                    subrows.sort((subrow1, subrow2) => {
                        const comparator = groupBy === "asset" ? "cpName" : "asset";

                        return subrow1[comparator].localeCompare(subrow2[comparator]);
                    });

                    if (groupBy === "asset") {
                        return {
                            asset: groupId,
                            subrows,
                            valueUSD,
                            plannedValueUSD,
                        };
                    }

                    return {
                        cpId: Number(groupId),
                        cpName: getCpName(groupId, "full"),
                        subrows:
                            Number(groupId) === HRP_MASTER_ID
                                ? [
                                      {
                                          isHRPClearingTimeInfoRow: true as const,
                                      },
                                      ...subrows,
                                  ]
                                : subrows,
                        valueUSD,
                        plannedValueUSD,
                    };
                })
                .sort((expandableRow1, expandableRow2) => {
                    if (
                        isGroupedByAssetExpandableRow(expandableRow1) &&
                        isGroupedByAssetExpandableRow(expandableRow2)
                    ) {
                        return expandableRow1.asset.localeCompare(expandableRow2.asset);
                    }
                    if (
                        isGroupedByCpExpandableRow(expandableRow1) &&
                        isGroupedByCpExpandableRow(expandableRow2)
                    ) {
                        return expandableRow1.cpName.localeCompare(expandableRow2.cpName);
                    }
                    return 0;
                }),
        ];
    }, [isLoading, groupBy, groupedPositionsData, getCpName]);

    const getSubRows = useCallback((row) => row.subrows, []);
    return (
        <Container spacing={8}>
            <CardContainer flex={1} asCard>
                <AuthorizedPositionsTable
                    isLoading={isLoading}
                    data={data}
                    getSubRows={getSubRows}
                    groupBy={groupBy}
                />
            </CardContainer>
        </Container>
    );
};
