import { VStack } from "@fm-frontend/uikit";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import styled from "styled-components";
import { LastRfqAccordion } from "./LastRfqAccordion/LastRfqAccordion";
import { PositionsAccordion } from "./PositionsAccordion/PositionsAccordion";

const Layout = styled(VStack)`
    gap: 8px;
    padding-bottom: 12px;
`;

const ScrollWrapper = styled.div`
    height: 100%;
    overflow: auto;
`;

export const RfqInfoTables = ({ className }: { className?: string }) => {
    return (
        <BottomFixTableContainer>
            <ScrollWrapper>
                <Layout className={className}>
                    <PositionsAccordion />
                    <LastRfqAccordion />
                </Layout>
            </ScrollWrapper>
        </BottomFixTableContainer>
    );
};
