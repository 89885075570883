import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { POSITION_COUNTERPARTY_ID } from "const/position";
import { useSubaccounts } from "feature/subaccounts/api";
import { useMemo } from "react";
import { useCounterpartyLimitsSnapshot } from "store/hooks";
import { composeUsername } from "utils";

export const useClientOptionsForAuthorizedTrading = () => {
    const { data: counterpartiesLimits } = useCounterpartyLimitsSnapshot();
    const { subaccounts } = useSubaccounts();

    return useMemo(() => {
        const subTakersForAuthorizedTrading = subaccounts.filter(
            ({ info, status, type }) =>
                info.clientId &&
                type === "taker" &&
                status === "active" &&
                counterpartiesLimits.some(
                    (cpLimit) => cpLimit[POSITION_COUNTERPARTY_ID] === info.clientId,
                ),
        );
        return subTakersForAuthorizedTrading
            .map((value) => {
                return {
                    text: composeUsername(value.info.username),
                    value: value.info.clientId,
                } as DropdownOption<number>;
            })
            .sort((a, b) => a.text.localeCompare(b.text));
    }, [subaccounts, counterpartiesLimits]);
};
