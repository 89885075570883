import { HStack, VStack } from "@fm-frontend/uikit";
import { ErrorBoundary } from "components/ErrorBoundary";
import {
    AuthorizedClientId,
    useAuthorizedClientId,
} from "feature/trade/hooks/useAuthorizedClientId";
import { useInstrumentsBooks } from "feature/trade/hooks/useInstrumentsBooks";
import { useTradingInstruments } from "feature/trade/hooks/useTradingInstruments";
import { AuthorizedLastFirmBookAccordion } from "feature/trade/trading/AuthorizedLastFirmBookAccordion";
import { AuthorizedPositionsAccordion } from "feature/trade/trading/AuthorizedPositionsAccordion";
import { InstrumentSelector } from "feature/trade/trading/InstrumentSelector";
import { LastFirmBookAccordion } from "feature/trade/trading/LastFirmBookAccordion";
import { MarketDepthSection } from "feature/trade/trading/MarketDepthSection";
import { PositionsAccordion } from "feature/trade/trading/PositionsAccordion";
import { SelectedInstrumentContext } from "feature/trade/trading/SelectedInstrumentContext";
import { TradingBottomFixContainer } from "feature/trade/trading/TradingBottomFixContainer";
import { TradingTile } from "feature/trade/trading/TradingTile";
import { AddInstrumentTile } from "feature/trade/trading/TradingTile/AddInstrumentTile";
import { NoTilesTile } from "feature/trade/trading/TradingTile/NoTilesTile";
import { EMPTY_BOOK } from "feature/trade/trading/utils";
import { EventContext, useEventEmitter } from "hooks/useEventEmitter";
import { useCallback, useRef } from "react";
import { useHasTakerRoleUser, useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import styled from "styled-components";
import { Book } from "types";
import { Cluster } from "./style";

const TilesContainer = styled.div`
    display: grid;
    grid-gap: 16px 8px;
    grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
    padding-bottom: 40px;
`;

const LeftColumn = styled.div`
    width: 100%;
`;

const RightColumn = styled.div`
    width: 564px;
    min-width: 564px;
    max-width: 564px;
`;

const Layout = styled(HStack)`
    min-width: 850px;
    height: 100%;
    gap: 8px;
    padding-right: 12px;

    @media (max-width: 1440px) {
        ${LeftColumn} {
            width: 50%;
        }
        ${RightColumn} {
            width: 50%;
            max-width: unset;
        }
    }
    @media (max-width: 1359px) {
        ${LeftColumn} {
            width: 35%;
        }
        ${RightColumn} {
            width: 65%;
            max-width: unset;
        }
    }
    @media (max-width: 1144px) {
        ${LeftColumn} {
            width: 278px;
            min-width: 278px;
        }
        ${RightColumn} {
            flex: 1;
            max-width: unset;
        }
    }
`;

const TablesLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 40px;
`;

const TakerTradingView = () => {
    const instrumentSelectorRef = useRef<HTMLInputElement>(null);
    const {
        favoriteInstrumentsIds,
        selectedInstrument,
        isTopFavoriteInstrument,
        removeInstrument,
        addInstrument,
    } = useTradingInstruments();
    const eventEmitter = useEventEmitter();
    const { instrumentsBooks } = useInstrumentsBooks();
    const selectedBook = instrumentsBooks.get(selectedInstrument ?? "") ?? null;
    const isBookLoading = selectedBook ? selectedBook.isLoading : selectedInstrument !== undefined;
    const resetErrors = useCallback(() => eventEmitter.emit?.({ type: "reset" }), [eventEmitter]);

    return (
        <EventContext.Provider value={eventEmitter}>
            <SelectedInstrumentContext.Provider value={{ instrument: selectedInstrument }}>
                <Layout>
                    <LeftColumn>
                        <VStack height="100%">
                            <InstrumentSelector
                                favoriteInstruments={favoriteInstrumentsIds}
                                onInstrumentDeselect={removeInstrument}
                                onInstrumentSelect={addInstrument}
                                ref={instrumentSelectorRef}
                            />
                            <TradingBottomFixContainer>
                                <TilesContainer>
                                    {favoriteInstrumentsIds.length > 0 && (
                                        <>
                                            {favoriteInstrumentsIds.map((instrumentName) => {
                                                return (
                                                    <ErrorBoundary noUI key={instrumentName}>
                                                        <TradingTile
                                                            instrumentName={instrumentName}
                                                            selectedBook={
                                                                instrumentsBooks.get(instrumentName)
                                                                    ?.data ?? EMPTY_BOOK
                                                            }
                                                            isActive={
                                                                instrumentName ===
                                                                selectedInstrument
                                                            }
                                                            isTopFavoriteInstrument={isTopFavoriteInstrument(
                                                                instrumentName,
                                                            )}
                                                            resetErrors={resetErrors}
                                                        />
                                                    </ErrorBoundary>
                                                );
                                            })}
                                            <AddInstrumentTile
                                                onAddInstrumentClick={() => {
                                                    setTimeout(
                                                        () =>
                                                            instrumentSelectorRef.current?.focus(),
                                                        0,
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                    {favoriteInstrumentsIds.length === 0 && <NoTilesTile />}
                                </TilesContainer>
                            </TradingBottomFixContainer>
                        </VStack>
                    </LeftColumn>
                    <RightColumn>
                        <TradingBottomFixContainer>
                            <TablesLayout>
                                <ErrorBoundary noUI>
                                    <MarketDepthSection
                                        isLoading={isBookLoading}
                                        selectedBook={selectedBook?.data ?? EMPTY_BOOK}
                                        errorCode={selectedBook?.errorCode}
                                        selectedInstrument={selectedInstrument}
                                    />
                                </ErrorBoundary>
                                <ErrorBoundary noUI>
                                    <PositionsAccordion />
                                </ErrorBoundary>
                                <ErrorBoundary noUI>
                                    <LastFirmBookAccordion />
                                </ErrorBoundary>
                            </TablesLayout>
                        </TradingBottomFixContainer>
                    </RightColumn>
                </Layout>
            </SelectedInstrumentContext.Provider>
        </EventContext.Provider>
    );
};

const getSelectedBook = (
    instrumentName: string | undefined,
    instrumentsBooks: Map<
        string,
        {
            data: Book;
            isLoading: boolean;
            errorCode: number | undefined;
        }
    >,
    authorizedClientId: AuthorizedClientId,
) => {
    const key = JSON.stringify({ instrument: instrumentName, clientId: authorizedClientId });

    return instrumentsBooks.get(key);
};
const PrimeBrokerTradingView = () => {
    const { authorizedClientId } = useAuthorizedClientId();
    const instrumentSelectorRef = useRef<HTMLInputElement>(null);
    const {
        favoriteInstrumentsIds,
        selectedInstrument,
        isTopFavoriteInstrument,
        removeInstrument,
        addInstrument,
    } = useTradingInstruments();
    const eventEmitter = useEventEmitter();
    const { instrumentsBooks } = useInstrumentsBooks();
    const selectedBook =
        getSelectedBook(selectedInstrument, instrumentsBooks, authorizedClientId) ?? null;
    const isBookLoading = selectedBook ? selectedBook.isLoading : selectedInstrument !== undefined;
    const resetErrors = useCallback(() => eventEmitter.emit?.({ type: "reset" }), [eventEmitter]);

    return (
        <EventContext.Provider value={eventEmitter}>
            <SelectedInstrumentContext.Provider value={{ instrument: selectedInstrument }}>
                <Layout>
                    <LeftColumn>
                        <VStack height="100%">
                            <InstrumentSelector
                                favoriteInstruments={favoriteInstrumentsIds}
                                onInstrumentDeselect={removeInstrument}
                                onInstrumentSelect={addInstrument}
                                ref={instrumentSelectorRef}
                            />
                            <TradingBottomFixContainer>
                                <TilesContainer>
                                    {favoriteInstrumentsIds.length > 0 && (
                                        <>
                                            {favoriteInstrumentsIds.map((instrumentName) => {
                                                return (
                                                    <ErrorBoundary noUI key={instrumentName}>
                                                        <TradingTile
                                                            instrumentName={instrumentName}
                                                            selectedBook={
                                                                getSelectedBook(
                                                                    instrumentName,
                                                                    instrumentsBooks,
                                                                    authorizedClientId,
                                                                )?.data ?? EMPTY_BOOK
                                                            }
                                                            isActive={
                                                                instrumentName ===
                                                                selectedInstrument
                                                            }
                                                            isTopFavoriteInstrument={isTopFavoriteInstrument(
                                                                instrumentName,
                                                            )}
                                                            resetErrors={resetErrors}
                                                            authorizedClientId={authorizedClientId}
                                                            key={`${instrumentName}-${authorizedClientId}`}
                                                        />
                                                    </ErrorBoundary>
                                                );
                                            })}
                                            <AddInstrumentTile
                                                onAddInstrumentClick={() => {
                                                    setTimeout(
                                                        () =>
                                                            instrumentSelectorRef.current?.focus(),
                                                        0,
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                    {favoriteInstrumentsIds.length === 0 && <NoTilesTile />}
                                </TilesContainer>
                            </TradingBottomFixContainer>
                        </VStack>
                    </LeftColumn>
                    <RightColumn>
                        <TradingBottomFixContainer>
                            <TablesLayout>
                                <ErrorBoundary noUI>
                                    <MarketDepthSection
                                        isLoading={isBookLoading}
                                        selectedBook={selectedBook?.data ?? EMPTY_BOOK}
                                        errorCode={selectedBook?.errorCode}
                                        selectedInstrument={selectedInstrument}
                                    />
                                </ErrorBoundary>
                                <ErrorBoundary noUI>
                                    <AuthorizedPositionsAccordion
                                        authorizedClientId={authorizedClientId}
                                    />
                                </ErrorBoundary>
                                <ErrorBoundary noUI>
                                    <AuthorizedLastFirmBookAccordion
                                        authorizedClientId={authorizedClientId}
                                    />
                                </ErrorBoundary>
                            </TablesLayout>
                        </TradingBottomFixContainer>
                    </RightColumn>
                </Layout>
            </SelectedInstrumentContext.Provider>
        </EventContext.Provider>
    );
};

export const FirmBookTradingPageContent = () => {
    const hasTakerRoleUser = useHasTakerRoleUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    if (hasTakerRoleUser) {
        return <TakerTradingView />;
    }
    if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
        return <PrimeBrokerTradingView />;
    }

    return (
        <TradingBottomFixContainer>
            <Cluster>
                <ErrorBoundary noUI>
                    <PositionsAccordion />
                </ErrorBoundary>
                <ErrorBoundary noUI>
                    <LastFirmBookAccordion />
                </ErrorBoundary>
            </Cluster>
        </TradingBottomFixContainer>
    );
};
