import { ColoredTabs } from "@fm-frontend/uikit";
import { when } from "@fm-frontend/utils";
import { LpBadge } from "components/LpBadge";
import { SEARCH_PARAMS } from "const";
import { setIsMobileMenuOpen } from "feature/app";
import { ROUTES } from "feature/app/router";
import { AdditionalMobileMenuOptions } from "feature/mainMenu/AdditionalMobileMenuOptions";
import { SubaccountsMenuButtonContainer } from "feature/subaccounts";
import { useIsProvidersSectionAvailable } from "hooks/useIsProvidersSectionAvailable";
import { useSearchParamState } from "hooks/useSearchParamState";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useHasMakerRoleUser, useViewTypeBackground } from "store/hooks";
import { Spacer } from "style";
import { MenuContent } from "style/menu";
import styled, { css, useTheme } from "styled-components";
import { BrokerViewType } from "types";

const Container = styled.div`
    margin-top: 13px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const MenusContainer = styled.div`
    overflow: hidden;
    height: 100%;
    flex: 1;
`;

const menuContainerCss = css<{ opened: boolean }>`
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    transition: all 0.2s linear;
    ${(p) =>
        p.opened &&
        css`
            transform: translateX(-100%);
        `}
`;
const CounterpartiesMenuContainer = styled.div`
    ${menuContainerCss}
`;
const SubaccountsMenuContent = styled(MenuContent)<{ opened: boolean }>`
    ${menuContainerCss}

    ${SubaccountsMenuButtonContainer} {
        margin-left: 0;
        margin-bottom: 0;
    }
`;
const TabsWrapper = styled.div<{ $background: string }>`
    padding: 0 8px;

    position: sticky;
    top: -8px;
    z-index: 10;

    background: ${(p) => p.$background};
`;

const COUNTERPARTIES_SUBACCOUNTS_REDIRECTIONS: Record<string, string> = {
    "/transactions": "/subaccountsViewTransactions",
    "/providers": "/subaccounts",
};
const SUBACCOUNTS_COUNTERPARTIES_REDIRECTIONS = Object.entries(
    COUNTERPARTIES_SUBACCOUNTS_REDIRECTIONS,
).reduce<Record<string, string>>(
    (acc, [route1, route2]) => ({
        ...acc,
        [route2]: route1,
    }),
    {},
);

const subscribers = new Set<() => void>();
export const useHandlePrimeBrokerViewTypeChange = (handler: () => void) => {
    useEffect(() => {
        subscribers.add(handler);

        return () => {
            subscribers.delete(handler);
        };
    }, [handler]);
};

export const PrimeBrokerMenuContent = memo(() => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const primeBrokerViewTabs = useMemo(
        () => [
            {
                value: "counterparties" as BrokerViewType,
                label: "Counterparties",
            },
            {
                value: "subaccounts" as BrokerViewType,
                label: "Sub-accounts",
                activeColor: theme.colors.subpurple100,
            },
        ],
        [],
    );
    const isProvidersSectionAvailable = useIsProvidersSectionAvailable();

    const [primeBrokerViewType, setPrimeBrokerViewType, clearPrimeBrokerViewType] =
        useSearchParamState<BrokerViewType>(SEARCH_PARAMS.primeBrokerViewType, "counterparties", {
            persist: true,
            resetSearch: true,
        });

    const viewTypeBackground = useViewTypeBackground();
    const history = useHistory();
    const location = useLocation();

    const handlePrimeBrokerViewTypeChange = useCallback((value) => {
        if (value === "subaccounts") {
            setPrimeBrokerViewType("subaccounts");
        } else {
            clearPrimeBrokerViewType();
        }
        [...subscribers].forEach((handler) => {
            handler();
        });
    }, []);
    useEffect(() => {
        // when we switch between counterparties and subaccounts view
        // check if it was specific page and then redirect to
        if (primeBrokerViewType === "subaccounts") {
            if (COUNTERPARTIES_SUBACCOUNTS_REDIRECTIONS[location.pathname]) {
                history.push(COUNTERPARTIES_SUBACCOUNTS_REDIRECTIONS[location.pathname]);
            }
        } else {
            if (SUBACCOUNTS_COUNTERPARTIES_REDIRECTIONS[location.pathname]) {
                history.push(SUBACCOUNTS_COUNTERPARTIES_REDIRECTIONS[location.pathname]);
            }
        }
    }, [primeBrokerViewType]);

    const hasMakerRoleUser = useHasMakerRoleUser();

    return (
        <>
            <Container>
                <TabsWrapper $background={viewTypeBackground}>
                    <ColoredTabs
                        gap="auto"
                        tabs={primeBrokerViewTabs}
                        value={primeBrokerViewType}
                        onChange={handlePrimeBrokerViewTypeChange}
                    />
                </TabsWrapper>
                <MenusContainer>
                    <CounterpartiesMenuContainer opened={primeBrokerViewType === "subaccounts"}>
                        <MenuContent
                            primeBrokerSubaccountsView={false}
                            onClick={(event) => {
                                if ((event.target as any).tagName === "A") {
                                    dispatch(setIsMobileMenuOpen(false));
                                }
                            }}
                        >
                            <div>Trade</div>
                            <NavLink
                                to="/trading"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push(ROUTES.trading);
                                }}
                            >
                                Trading
                            </NavLink>
                            <NavLink to={ROUTES.positions}>Positions</NavLink>
                            <NavLink to="/riskmanagement">Risk management</NavLink>
                            {when(hasMakerRoleUser, <NavLink to="/orders">Orders</NavLink>)}
                            <NavLink to={ROUTES.assetsControl}>Assets & Instruments</NavLink>

                            <div>Settlement</div>
                            <NavLink to="/transactions">Transactions</NavLink>
                            <NavLink to={ROUTES.addresses}>Addresses</NavLink>

                            <div>History</div>
                            <NavLink to={ROUTES.historyTrades}>Trading</NavLink>
                            <NavLink to="/settlements">Settlements</NavLink>

                            <div>API</div>
                            <NavLink to="/keys">Keys</NavLink>

                            <AdditionalMobileMenuOptions />
                        </MenuContent>
                        <Spacer />
                        {isProvidersSectionAvailable && <LpBadge to="/providers" />}
                    </CounterpartiesMenuContainer>
                    <SubaccountsMenuContent
                        opened={primeBrokerViewType === "subaccounts"}
                        primeBrokerSubaccountsView={true}
                        onClick={(event) => {
                            if ((event.target as any).tagName === "A") {
                                dispatch(setIsMobileMenuOpen(false));
                            }
                        }}
                    >
                        <div>Trade</div>
                        <NavLink
                            to="/trading"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push(ROUTES.trading);
                            }}
                        >
                            Trading
                        </NavLink>
                        <NavLink to={ROUTES.positions}>Positions</NavLink>
                        <NavLink to="/riskmanagement">Risk management</NavLink>
                        {when(hasMakerRoleUser, <NavLink to="/orders">Orders</NavLink>)}
                        <NavLink to={ROUTES.assetsControl}>Assets & Instruments</NavLink>

                        <div>Settlement</div>
                        <NavLink to="/subaccountsViewTransactions">Transactions</NavLink>
                        <NavLink to={ROUTES.addresses}>Addresses</NavLink>

                        <div>History</div>
                        <NavLink to={ROUTES.historyTrades}>Trading</NavLink>
                        <NavLink to="/settlements">Settlements</NavLink>

                        <div>API</div>
                        <NavLink to="/keys">Keys</NavLink>

                        <AdditionalMobileMenuOptions />
                        <Spacer />
                    </SubaccountsMenuContent>
                </MenusContainer>
            </Container>
        </>
    );
});

PrimeBrokerMenuContent.displayName = "PrimeBrokerMenuContent";
