import { Popover } from "@fm-frontend/uikit";
import { useDispatch } from "react-redux";
import { setIsInboxOpen } from "feature/app";
import { useSelector } from "hooks";
import { InboxPopoverContent } from "./InboxPopoverContent/InboxPopoverContent";
import { NotificationsButton } from "./NotificationsButton/NotificationsButton";
import { notificationsFetchCountRequest, notificationsFetchRequest } from "./notifications.store";

export const InboxPopover = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.app.isInboxOpen);

    const closePopover = () => dispatch(setIsInboxOpen(false));

    const handleToggleNotifications = () => {
        if (!isOpen) {
            dispatch(notificationsFetchRequest({ page: 1 }));
            dispatch(notificationsFetchCountRequest());
        }
        dispatch(setIsInboxOpen(!isOpen));
    };

    return (
        <Popover
            isOpen={isOpen}
            padding={8}
            positions={["bottom"]}
            align="end"
            onClickOutside={closePopover}
            content={<InboxPopoverContent closePopover={closePopover} />}
        >
            <NotificationsButton onClick={handleToggleNotifications} />
        </Popover>
    );
};
