import { SIZE64 } from "@fm-frontend/utils";
import { createColumnHelper } from "@tanstack/react-table";
import format from "date-fns/format";
import { useInstruments } from "hooks";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import styled from "styled-components";
import { DealSide, PreparedDeal } from "types";

const columnHelper = createColumnHelper<PreparedDeal>();

const SIDE_TITLES: Record<PreparedDeal["side"], string> = {
    [DealSide.Buy]: "Buy",
    [DealSide.Sell]: "Sell",
};

const TimeCell = styled.div`
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.04px;
`;
const InstrumentCell = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
`;
const SideSizeCell = styled.div<{ $side: PreparedDeal["side"] }>`
    color: ${({ $side, theme: { colors } }) =>
        $side === DealSide.Buy ? colors.positive100 : colors.negative100};
    font-size: 12px;
    line-height: 16px;
`;
const PriceCell = styled.div`
    font-size: 12px;
    line-height: 16px;
`;

export const tableColumns = [
    columnHelper.accessor((row) => row.dealMoment, {
        id: "time",
        cell: (rowData) => {
            const dealMoment = rowData.getValue();
            return <TimeCell>{format(dealMoment, "dd LLL HH:mm:ss")}</TimeCell>;
        },
        header: "Time",
        meta: {
            cellStyleProps: {
                width: "48px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row.instrument, {
        id: "instrument",
        cell: (rowData) => {
            const instrumentName = rowData.getValue();
            return <InstrumentCell>{instrumentName}</InstrumentCell>;
        },
        header: "Instrument",
        meta: {
            cellStyleProps: {
                width: "110px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row.side, {
        id: "side",
        cell: (rowData) => {
            const side = rowData.getValue();
            return <SideSizeCell $side={side}>{SIDE_TITLES[side]}</SideSizeCell>;
        },
        header: "Side",
        meta: {
            cellStyleProps: {
                width: "32px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),

    columnHelper.accessor((row) => row.size, {
        id: "size",
        cell: (rowData) => {
            const size = rowData.getValue();
            const { side, instrument } = rowData.row.original;
            const { intrumentsByNameObj } = useInstruments();
            const { toSize64FormattedStrByAsset } = useSize64AssetFormatHelpers();
            return (
                <SideSizeCell $side={side}>
                    {toSize64FormattedStrByAsset(
                        size,
                        intrumentsByNameObj[instrument]?.assetCurrency,
                    )}
                </SideSizeCell>
            );
        },
        header: "Size",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor((row) => row.price, {
        id: "price",
        cell: (rowData) => {
            const price = rowData.getValue();
            return <PriceCell>{SIZE64.toSize64FormattedStr(price, 8)}</PriceCell>;
        },
        header: "Price",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
];
