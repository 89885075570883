import { Accordion, HStack } from "@fm-frontend/uikit";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useAuthorizedClientInfo } from "feature/trade/hooks/useAuthorizedClientInfo";
import styled from "styled-components";
import { AuthorizedLastFirmBookAccordionContent } from "./AuthorizedLastFirmBookAccordionContent";

const Caption = styled.span`
    color: ${(p) => p.theme.colors.ui72};
    text-transform: uppercase;
`;

const LastFirmBookAccordionHeader = styled(HStack)`
    gap: 12px;
    align-items: center;
    ${Caption} {
        flex: 1;
    }
`;

export const AuthorizedLastFirmBookAccordion = ({
    authorizedClientId,
}: {
    authorizedClientId: AuthorizedClientId;
}) => {
    const authorizedClientInfo = useAuthorizedClientInfo(authorizedClientId);

    const authorizedTradingTitle = authorizedClientInfo
        ? `${authorizedClientInfo.username}'s last trades`
        : "";

    const header = (
        <LastFirmBookAccordionHeader>
            <Caption>{authorizedTradingTitle}</Caption>
        </LastFirmBookAccordionHeader>
    );

    return (
        <Accordion
            defaultExpanded
            header={header}
            content={<AuthorizedLastFirmBookAccordionContent />}
        />
    );
};
