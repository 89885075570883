import React, { ButtonHTMLAttributes, FC, forwardRef } from "react";
import styled from "styled-components";
import { Loading } from "./EmptyState";
import { List } from "./List";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    font-size: 12px;
    min-width: 540px;
`;

const Body = styled.div<{ isExpanded: boolean }>`
    min-height: ${(p) => (p.isExpanded ? "800px" : "320px")};
    display: flex;
    flex-direction: row;
    overflow: auto;
`;

const ActionContainer = styled.button`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    padding: 12px;
    justify-content: center;
    width: 100%;
    cursor: pointer;

    color: ${(p) => p.theme.colors.ui72};
    text-transform: uppercase;

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;

export interface MarkerDepthExpanderProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isExpanded: boolean;
}

export const MarkerDepthExpander = forwardRef<HTMLButtonElement, MarkerDepthExpanderProps>(
    ({ isExpanded, ...props }, ref) => {
        return (
            <ActionContainer ref={ref} {...props} type="button">
                {isExpanded ? "Show less" : "Show all"}
            </ActionContainer>
        );
    },
);

type Level = [price: number | bigint, size: number | bigint];
type MarketDepthProps = {
    restrictionState?: React.ReactNode | null;
    loading: boolean;
    bidList: Level[];
    askList: Level[];
    fullDepth: boolean;
    handleFullDepth: () => void;
};

export const MarketDepth: FC<MarketDepthProps> = ({
    restrictionState,
    loading,
    bidList,
    askList,
    fullDepth,
    handleFullDepth,
}) => {
    const getContent = () => {
        if (loading) {
            return <Loading />;
        }
        if (restrictionState) {
            return <>{restrictionState}</>;
        }

        return (
            <>
                <List type="bid" list={bidList} />
                <List type="ask" list={askList} />
            </>
        );
    };

    return (
        <Container>
            <Body isExpanded={fullDepth}>{getContent()}</Body>
            <MarkerDepthExpander isExpanded={fullDepth} onClick={handleFullDepth} />
        </Container>
    );
};
