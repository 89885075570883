import { getAssetSignificantDigits, SIZE64, ValueFormat } from "@fm-frontend/utils";
import { useInstruments } from "hooks";

const isBigInt = (num: bigint) => {
    if (typeof num !== "bigint") {
        throw new TypeError(`${num} is not BigInt!`);
    }
};

export const useSize64AssetFormatHelpers = () => {
    const { currencies } = useInstruments();

    return {
        toSize64FormattedStrByAsset(size64Num: bigint, asset: string) {
            isBigInt(size64Num);

            if (currencies[asset] === undefined) {
                return "";
            }

            const { balanceStep } = currencies[asset];
            const fractionDigits = getAssetSignificantDigits(balanceStep);

            return ValueFormat.price(size64Num, fractionDigits);
        },
        isValidSize64StrBalanceStep(size64InputStr: string, asset: string) {
            if (currencies[asset] === undefined || !SIZE64.isValidSize64InputStr(size64InputStr)) {
                return false;
            }

            const { balanceStep } = currencies[asset];
            return !(SIZE64.fromSize64InputStr(size64InputStr) % BigInt(balanceStep));
        },
    };
};
