import {
    calculateRange,
    EXPAND_TABLE_COLUMN_KEY,
    Flex,
    Icons,
    Paginator,
    PSmall,
    Table,
} from "@fm-frontend/uikit";
import {
    ColumnDef,
    createColumnHelper,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { HelperContainer } from "components/Helper/HelperContainer";
import React, { useState } from "react";
import { useUserType } from "store/hooks";
import styled from "styled-components";
import { ClientType } from "types";
import { TradeHistory } from "../types";
import {
    ClientOrderIdCell,
    CpIdCell,
    DateCell,
    DeltaCell,
    GeneralCell,
    InstrumentCell,
    OrderIdCell,
    PriceCell,
    SideCell,
    SizeCell,
    VolumeCell,
} from "./cells";
import { LinkedTradeCell } from "./cells/LinkedTradeCell";
import { OrderTypeCell } from "./cells/OrderTypeCell";

const SizeHelper = () => (
    <HelperContainer>
        <PSmall>The size is indicated in the base currency</PSmall>
    </HelperContainer>
);

const VolumeHelper = () => (
    <HelperContainer>
        <PSmall>The volume is indicated in the quote currency</PSmall>
    </HelperContainer>
);

const DeltaHelper = () => (
    <HelperContainer>
        <PSmall>
            The client delta is indicated in the quote currency and shows the difference between
            client's position and your position caused by the trade.
        </PSmall>
        <PSmall>
            A <span>negative client delta</span> means the client's trade volume is less than yours,
            and <span>you gained on a trade</span>.
        </PSmall>
        <PSmall>
            A <span>positive client delta</span> indicates that{" "}
            <span>the client gained on a trade</span>, usually due to a negative markup.
        </PSmall>
    </HelperContainer>
);

const columnHelper = createColumnHelper<TradeHistory>();

type TradesHistoryTableProps = {
    data: TradeHistory[];
    pageItemsCount: number;
    allItemsCount: number;
    isLoading: boolean;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    onPrevClick?: () => void;
    onNextClick?: () => void;
};

export const TableRowExpandContainer = styled(Flex)`
    cursor: pointer;
`;

export const toggleColumn = columnHelper.display({
    id: EXPAND_TABLE_COLUMN_KEY,
    header: ({ table }) => {
        const isAllRowsExpanded = table.getIsAllRowsExpanded();

        return (
            <TableRowExpandContainer onClick={table.getToggleAllRowsExpandedHandler()}>
                {isAllRowsExpanded ? <Icons.CollapseAll /> : <Icons.ExpandAll />}
            </TableRowExpandContainer>
        );
    },
    cell: ({ row }) => {
        return !row.getCanExpand() ? null : row.getIsExpanded() ? (
            <Icons.Collapse />
        ) : (
            <Icons.Expand />
        );
    },
    meta: {
        headerStyleProps: {
            width: "32px",
            maxWidth: "32px",
        },
        cellStyleProps: {
            width: "32px",
            maxWidth: "32px",
        },
        sticky: true,
    },
});
const orderIdColumn = columnHelper.accessor((row) => row.orderId, {
    header: "Order ID",
    cell: (info) => {
        return <OrderIdCell orderId={info.getValue()} />;
    },
    meta: {
        sticky: true,
        lastStickyColumn: false,
    },
});
const orderTypeColumn = columnHelper.accessor((row) => row.orderType, {
    header: "Order type",
    cell: (info) => {
        return (
            <OrderTypeCell tradeType={info.row.original.tradeType} orderType={info.getValue()} />
        );
    },
    meta: {
        sticky: true,
        lastStickyColumn: true,
    },
});
const cpColumn = columnHelper.accessor((row) => row.cpId, {
    header: "Counterparty",
    cell: (info) => <CpIdCell cpId={info.getValue()} />,
    meta: {
        cellStyleProps: {
            maxWidth: "160px",
        },
    },
});
const instrumentColumn = columnHelper.accessor((row) => row.instrument, {
    header: "Instrument",
    cell: (info) => <InstrumentCell instrument={info.getValue()} />,
});
const sideColumn = columnHelper.accessor((row) => row.side, {
    header: "Side",
    cell: (info) => <SideCell side={info.getValue()} />,
});
const priceColumn = columnHelper.accessor((row) => row.price, {
    header: "Price",
    cell: (info) => {
        const price = info.getValue();
        const { side } = info.row.original;

        return <PriceCell price={price} side={side} />;
    },
    meta: {
        headerStyleProps: {
            minWidth: "160px",
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const sizeColumn = (header: string) =>
    columnHelper.accessor((row) => row.size, {
        header: header,
        cell: (info) => <SizeCell size={info.getValue()} />,
        meta: {
            headerHelper: <SizeHelper />,
            headerStyleProps: {
                minWidth: "160px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    });
const volumeColumn = (header: string) =>
    columnHelper.accessor((row) => row.volume, {
        header: header,
        cell: (info) => <VolumeCell volume={info.getValue()} />,
        meta: {
            headerHelper: <VolumeHelper />,
            headerStyleProps: {
                minWidth: "160px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    });
const deltaColumn = columnHelper.accessor((row) => row.delta, {
    header: "Client delta",
    cell: (info) => <DeltaCell delta={info.getValue()} />,
    meta: {
        headerHelper: <DeltaHelper />,
        headerHelperOptions: { positions: ["bottom"] },
        headerStyleProps: {
            minWidth: "124px",
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const tradeIdColumn = columnHelper.accessor((row) => row.tradeId, {
    header: "Trade ID",
    cell: (info) => <GeneralCell value={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const linkedTradeColumn = columnHelper.accessor((row) => row.linkedTrade, {
    header: "Linked trade",
    cell: (info) => <LinkedTradeCell linkedTradeId={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const dateColumn = columnHelper.accessor((row) => row.date, {
    id: "date",
    header: "Date",
    cell: (info) => <DateCell date={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            minWidth: "88px",
            textAlign: "right",
        },
    },
});
const clientOrderIdColumn = columnHelper.accessor((row) => row.clientOrderId, {
    header: "Client order ID",
    cell: (info) => <ClientOrderIdCell clientOrderId={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});

const generalColumns = [
    orderIdColumn,
    orderTypeColumn,
    cpColumn,
    instrumentColumn,
    sideColumn,
    priceColumn,
];
const pbPartColumns = [
    deltaColumn,
    dateColumn,
    tradeIdColumn,
    linkedTradeColumn,
    clientOrderIdColumn,
];
const takerPartColumns = [dateColumn, tradeIdColumn, clientOrderIdColumn];
const makerPartColumns = [deltaColumn, dateColumn, tradeIdColumn, clientOrderIdColumn];

const makeTableColumns = (config: {
    isExpandColumnNeeded: boolean;
    userType: ClientType | undefined;
}) => {
    let columns: ColumnDef<TradeHistory, any>[] = [...generalColumns];

    if (config.isExpandColumnNeeded) {
        columns = [
            toggleColumn,
            ...columns,
            sizeColumn("Total size"),
            volumeColumn("Total volume"),
        ];
    }
    if (!config.isExpandColumnNeeded) {
        columns = [...columns, sizeColumn("Size"), volumeColumn("Volume")];
    }

    if (config.userType === "primeBroker") {
        columns = [...columns, ...pbPartColumns];
    }
    if (config.userType === "maker" || config.userType === "subaccountMaker") {
        columns = [...columns, ...makerPartColumns];
    }
    if (config.userType === "taker" || config.userType === "subaccountTaker") {
        columns = [...columns, ...takerPartColumns];
    }

    return columns;
};

export const Content: React.FC<TradesHistoryTableProps> = ({
    data,
    pageItemsCount,
    allItemsCount,
    isLoading,
    hasPrevPage,
    hasNextPage,
    onPrevClick,
    onNextClick,
}) => {
    const userType = useUserType();
    const [expanded, setExpanded] = useState<ExpandedState>(true);
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: "date",
            desc: true,
        },
    ]);

    const isExpandColumnNeeded = data.some((d) => d.subRows?.length);

    const columns = makeTableColumns({
        isExpandColumnNeeded,
        userType,
    });

    return (
        <>
            <BottomFixTableContainer bottomOffset={40}>
                <Table
                    tableOptions={{
                        data: data,
                        columns: columns,
                        getCoreRowModel: getCoreRowModel(),
                        getSubRows: (row) => row.subRows,
                        getExpandedRowModel: getExpandedRowModel(),
                        state: {
                            expanded,
                            sorting,
                        },
                        onSortingChange: setSorting,
                        getSortedRowModel: getSortedRowModel(),
                        onExpandedChange: setExpanded,
                    }}
                    paginator={null}
                    onRowClick={(row, cellId) => {
                        if (row.subRows?.length && cellId === EXPAND_TABLE_COLUMN_KEY) {
                            row.toggleExpanded();
                        }
                    }}
                    isLoading={isLoading}
                />
            </BottomFixTableContainer>
            <Paginator
                range={calculateRange(data, "date")}
                pageItemsCount={pageItemsCount}
                allItemsCount={allItemsCount}
                isLoading={isLoading}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
            />
        </>
    );
};
