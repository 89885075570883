import { HStack } from "@fm-frontend/uikit";
import { InstrumentIcon } from "components/InstrumentIcon";
import { EmDash } from "const";
import { useInstrumentInfo } from "hooks/useInstrumentInfo";
import React from "react";
import styled from "styled-components";
import { Spread } from "./Spread";

const Caption = styled.span`
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${(p) => p.theme.colors.ui72};
    text-transform: uppercase;
`;

type TitleProps = {
    instrument: string | undefined;
    firmBook?: boolean;
    bidPrice: bigint | null;
    askPrice: bigint | null;
};

export const Title: React.FC<TitleProps> = ({ instrument = EmDash, bidPrice, askPrice }) => {
    const { quoteAsset } = useInstrumentInfo(instrument);

    return (
        <HStack justifyContent="space-between" minHeight="24px">
            <Caption>
                <InstrumentIcon instrument={instrument} size={18} />
                {instrument} Order book
            </Caption>
            <Spread quoteAsset={quoteAsset} bidPrice={bidPrice} askPrice={askPrice} />
        </HStack>
    );
};
