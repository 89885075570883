import { EmDash, ValueFormat } from "@fm-frontend/utils";
import { calculateSpread } from "feature/trade/trading/TradingTile/utils/calculateSpread";
import { FC } from "react";
import styled from "styled-components";

const SpreadValue = styled.span`
    text-wrap: nowrap;
    color: ${(p) => p.theme.colors.ui100};

    span {
        color: ${(p) => p.theme.colors.ui52};
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
`;

type SpreadProps = {
    bidPrice: bigint | null;
    askPrice: bigint | null;
    quoteAsset: string | undefined;
};

export const Spread: FC<SpreadProps> = ({ bidPrice, askPrice, quoteAsset }) => {
    const { spread, spreadInPercentage } = calculateSpread({
        bidPrice: bidPrice,
        askPrice: askPrice,
    });

    const formattedSpread = spread === null ? EmDash : `${ValueFormat.price(spread)} ${quoteAsset}`;
    const formattedSpreadInPercentage =
        spreadInPercentage === null ? "" : `${spreadInPercentage.toFixed(3)}%`;

    return (
        <Container>
            <SpreadValue>
                Spread: {formattedSpread} <span>{formattedSpreadInPercentage}</span>
            </SpreadValue>
        </Container>
    );
};
