import { Icons, Tooltip } from "@fm-frontend/uikit";
import { SIZE64 } from "@fm-frontend/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { EmDash } from "const";
import format from "date-fns/format";
import { RfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useInstruments } from "hooks";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import styled, { useTheme } from "styled-components";

const columnHelper = createColumnHelper<RfqHistoryItem>();

const SIDE_TITLES: Record<RfqHistoryItem["side"], string> = {
    BUY: "Buy",
    SELL: "Sell",
};

const useStatusSettings = (): Record<
    RfqHistoryItem["status"],
    { title: string; color: string; bgColor: string }
> => {
    const { colors } = useTheme();

    return {
        CREATED: {
            title: "Created",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        CANCELED: {
            title: "Cancelled",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        EXPIRED: {
            title: "Expired",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        COMMITTED: {
            title: "Executed",
            color: colors.positive100,
            bgColor: colors.positive4,
        },
    };
};

const StatusSpecificContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    line-height: 16px;
    padding: 0 4px;
    border-radius: 4px;
    background-color: ${(p) => p.theme.colors.ui4};
    height: 16px;
`;
const Cancelled = () => {
    const theme = useTheme();

    return (
        <Tooltip content="Cancelled">
            <StatusSpecificContainer>
                <Icons.Disabled color={theme.colors.ui32} />
            </StatusSpecificContainer>
        </Tooltip>
    );
};

const CommittedContainer = styled(StatusSpecificContainer)`
    background-color: ${(p) => p.theme.colors.brand8};
`;
const Committed = () => {
    const theme = useTheme();

    return (
        <Tooltip content="Committed">
            <CommittedContainer>
                <Icons.Checkmark color={theme.colors.brand100} />
            </CommittedContainer>
        </Tooltip>
    );
};

const StatusContainer = styled.div<{ $color: string; $bgColor: string }>`
    display: inline-block;
    padding: 0 2px;
    border-radius: 4px;
    color: ${({ $color }) => $color};
    background-color: ${({ $bgColor }) => $bgColor};
`;
const Status = ({ status }: { status: RfqHistoryItem["status"] }) => {
    const settingsObj = useStatusSettings();

    if (status === "CANCELED") {
        return <Cancelled />;
    }
    if (status === "COMMITTED") {
        return <Committed />;
    }

    const { title, color, bgColor } = settingsObj[status];

    return (
        <StatusContainer $color={color} $bgColor={bgColor}>
            {title}
        </StatusContainer>
    );
};

const TimeCell = styled.div`
    font-size: 8px;
    line-height: 10px;
`;
const InstrumentCell = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
`;
const SidePriceCell = styled.div<{ $side: RfqHistoryItem["side"] }>`
    color: ${({ $side, theme: { colors } }) =>
        $side === "BUY" ? colors.positive100 : colors.negative100};
    font-size: 12px;
    line-height: 16px;
`;
const SizeCell = styled.div`
    font-size: 12px;
    line-height: 16px;
`;

export const tableColumns = [
    columnHelper.accessor((row) => row.date, {
        id: "time",
        cell: (rowData) => {
            const createdAt = rowData.getValue();
            return <TimeCell>{format(createdAt, "dd LLL HH:mm:ss")}</TimeCell>;
        },
        header: "Time",
        meta: {
            cellStyleProps: {
                width: "48px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row.instrumentName, {
        id: "instrument",
        cell: (rowData) => {
            const instrumentName = rowData.getValue();
            return <InstrumentCell>{instrumentName}</InstrumentCell>;
        },
        header: "Instrument",
        meta: {
            cellStyleProps: {
                width: "110px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row.side, {
        id: "side",
        cell: (rowData) => {
            const side = rowData.getValue();
            return <SidePriceCell $side={side}>{SIDE_TITLES[side]}</SidePriceCell>;
        },
        header: "Side",
        meta: {
            cellStyleProps: {
                width: "36px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "price",
        cell: (rowData) => {
            const { side, price } = rowData.getValue();
            if (price === 0n) {
                return EmDash;
            }
            return <SidePriceCell $side={side}>{SIZE64.toSize64FormattedStr(price)}</SidePriceCell>;
        },
        header: "Price",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "size",
        cell: (rowData) => {
            const { size, instrumentName } = rowData.getValue();
            const { intrumentsByNameObj } = useInstruments();
            const { toSize64FormattedStrByAsset } = useSize64AssetFormatHelpers();
            return (
                <SizeCell>
                    {toSize64FormattedStrByAsset(
                        size,
                        intrumentsByNameObj[instrumentName]?.assetCurrency,
                    )}
                </SizeCell>
            );
        },
        header: "Size",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (rowData) => {
            const status = rowData.getValue();

            return <Status status={status} />;
        },
        header: "Status",
        meta: {
            cellStyleProps: {
                width: "75px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
];
