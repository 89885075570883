import { Flex, VStack } from "@fm-frontend/uikit";
import { TRADING_LAST_TRADES_LIMIT } from "feature/trade/trading/utils";
import { useLastTrades } from "store/useLastTrades";
import styled from "styled-components";
import { LastFirmBookTable } from "./LastFirmBookTable";

const Container = styled(Flex)`
    overflow: auto;
    padding: 0 1px 1px 1px;
    border-radius: 16px;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const LastFirmBookAccordionContent = () => {
    const { trades, isLoading } = useLastTrades(TRADING_LAST_TRADES_LIMIT);

    return (
        <Container spacing={8}>
            <CardContainer flex={1} asCard>
                <LastFirmBookTable trades={trades} isLoading={isLoading} />
            </CardContainer>
        </Container>
    );
};
