import packageJson from "../../package.json";
import { getBooleanEnvVariable, getEnv } from "@fm-frontend/utils";

export const isDev = (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) === "development";
export const appUrl = getEnv().REACT_APP_API_URL || location.host;
export const wsUrl = `${location.protocol === "https:" ? "wss://" : "ws://"}${
    isDev ? new URL(packageJson.proxy).host : appUrl
}`;
export const GA_MEASUREMENT_ID = getEnv().REACT_APP_GA_MEASUREMENT_ID;
export const IS_TEST_ENV = getBooleanEnvVariable(getEnv().REACT_APP_TEST_ENV);
export const IS_ONBOARDING_ACTIVE = getBooleanEnvVariable(getEnv().REACT_APP_ONBOARDING_ACTIVE);

export const getEnvTitle = () => {
    if (window.location.host.startsWith("localhost")) {
        return "localhost";
    }

    const hostSubdomains = window.location.host.split(".");

    switch (process.env.REACT_APP_FM_BUILD_FLAVOR) {
        case "finery":
            return hostSubdomains[1];
        case "coinrate":
        case "fm-cy":
        case "payperless":
        case "integrityone":
        default:
            return hostSubdomains[0];
    }
};

export const get2FATitle = () => {
    const projectName = getEnv().PROJECT_NAME;
    const envTitle = getEnvTitle();

    return `${projectName} ${envTitle}`;
};
