import { post } from "../../api";

export type OrderSides = "ask" | "bid";
export type LimitOrderTypes = "limitIOC" | "limitFOK";
type MarketOrderTypes = "marketIOC" | "marketFOK";
export type OrderTypes = LimitOrderTypes | MarketOrderTypes;
export enum AuthorizedTypes {
    AuthorizedTrade = "authorizedTrade",
    AuthorizedLiquidation = "authorizedLiquidation",
}

export type AddOrderParams = {
    instrument: string;
    side: OrderSides;
    size?: bigint | null;
    volume?: bigint | null;
    type: OrderTypes;
    price?: bigint | null;

    cod?: boolean;
    clientOrderId?: number;
};

export const addOrder = (params: AddOrderParams) => {
    return post("add", params);
};

export type AuthorizedAddOrderParams = {
    instrument: string;
    liquidation: boolean;
    side: OrderSides;
    size?: bigint | null;
    volume?: bigint | null;
    type: OrderTypes;
    price?: bigint | null;

    clientId: number;
};

export const authorizedAddOrder = (params: AuthorizedAddOrderParams) => {
    return post("authorized/add", params);
};
