import {
    ClientId,
    FIXED_MODAL_STYLE,
    HStack,
    Modal,
    PBold,
    PXSmall,
    VStack,
} from "@fm-frontend/uikit";
import { SIZE64 } from "@fm-frontend/utils";
import { InstrumentIcon } from "feature/assetsControl/components/InstrumentsTabContent/InstrumentIcon";
import { useRefreshLastRfqTrades } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useRfqProvidersAndInstruments } from "feature/rfq/apiHooks/useRfqProvidersAndInstruments";
import { QuoteSide, RfqRequestSide, useSendRfqRequest } from "feature/rfq/apiHooks/useRfqWs";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import { useEffect, useRef, useState } from "react";
import { useUsdPrices } from "store/hooks";
import styled from "styled-components";
import { displayError } from "utils";
import { CancelRequestButton } from "./CancelRequestButton";
import { SuccessedQuote } from "./QuotesPanel/QuotesList";
import { QuotesPanel } from "./QuotesPanel/QuotesPanel";
import { RequestAgainButton } from "./RequestAgainButton";
import { RequestTypeLabel } from "./RequestTypeLabel";
import { TimerWithProgressBar } from "./TimerWithProgressBar";
import { WaitingForQuotes } from "./WaitingForQuotes";

const TileHeader = styled(HStack)`
    position: relative;
    height: 32px;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
`;

const StyledInstrumentIcon = styled(InstrumentIcon)`
    position: absolute;
    left: 12px;
    bottom: 7px;
`;

const RequestInfo = styled(VStack)`
    margin: 0 8px;
    padding: 6px 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px ${(p) => p.theme.colors.ui4},
        0px -3px 0px 0px ${(p) => p.theme.colors.ui8} inset,
        0px 0px 0px 1px ${(p) => p.theme.colors.ui12} inset;
`;
const RequestInfoRow = styled(HStack)`
    align-items: center;
    justify-content: space-between;
    height: 24px;
`;
const RequestInfoRowLabel = styled(PXSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export type ExecutingQuote = {
    providerId: number;
    side: QuoteSide;
    isBestQuote?: boolean;
};

const RFQ_SESSIONS_IN_A_ROW = 5;

export const RfqRequestModal = ({
    closeModal,
    instrumentName,
    balanceCurrency,
    assetCurrency,
    requestSide,
    providersClientsIds,
    amount,
}: {
    closeModal: () => void;
    instrumentName: string;
    balanceCurrency: string;
    assetCurrency: string;
    requestSide: RfqRequestSide;
    providersClientsIds: number[];
    amount: bigint;
}) => {
    const refreshLastRfqTrades = useRefreshLastRfqTrades();
    const { toSize64FormattedStrByAsset } = useSize64AssetFormatHelpers();
    const { rfqProviders } = useRfqProvidersAndInstruments();
    const providersIds = providersClientsIds.map(
        (providerClientId) => rfqProviders.get(providerClientId)!.providerId,
    );

    const { priceObj, isLoading: usdPricesLoading } = useUsdPrices();

    const { quotes, commitQuote, cancelRequest, refreshQuotes, error } = useSendRfqRequest(
        instrumentName,
        providersIds,
        requestSide,
        amount,
    );

    const [isTimeFinished, setIsTimeFinished] = useState(false);
    const [executingQuote, setExecutingQuote] = useState<null | ExecutingQuote>(null);

    const firstSuccessedQuote = quotes.find((quote) => "price" in quote);
    const quoteExpiration =
        firstSuccessedQuote && "expiresAt" in firstSuccessedQuote
            ? firstSuccessedQuote.expiresAt
            : undefined;
    const isLoadingSuccessedQuote = quoteExpiration === undefined;

    const handleClose = Boolean(executingQuote)
        ? undefined
        : () => {
              refreshLastRfqTrades();
              cancelRequest();
              closeModal();
          };

    useEffect(() => {
        if (error !== null) {
            displayError(
                `RFQ WebSocket error: ${
                    error.errorMsg
                        ? `${error.errorMsg} (code ${error.errorCode})`
                        : `code ${error.errorCode}`
                }`,
            );
        }
    }, [error]);

    const handleQuoteSelect = async (selectedQuote: ExecutingQuote) => {
        try {
            const { providerId, side } = selectedQuote;
            setExecutingQuote(selectedQuote);
            await commitQuote(providerId, side);
            closeModal();
        } finally {
            setExecutingQuote(null);
            refreshLastRfqTrades();
        }
    };

    const bestQuote = quotes.reduce<SuccessedQuote | null>((acc, quote) => {
        if ("price" in quote && (acc === null || quote.price < acc.price)) {
            if (acc === null) {
                return quote;
            }
            if (requestSide === "SELL" && quote.price > acc.price) {
                return quote;
            }
            if (requestSide === "BUY" && quote.price < acc.price) {
                return quote;
            }
        }
        return acc;
    }, null);

    const allQuotesRejected =
        quotes.length === providersIds.length &&
        quotes.every((quote) => "rejectReason" in quote || "error" in quote);
    const rfqSessionFinishedByTimeout = isTimeFinished && !executingQuote;
    const rfqSessionIsFinished = rfqSessionFinishedByTimeout || allQuotesRejected;

    useEffect(() => {
        if (rfqSessionIsFinished) {
            refreshLastRfqTrades();
        }
    }, [rfqSessionIsFinished, refreshLastRfqTrades]);

    const rfqSessionNumberRef = useRef(1);
    useEffect(() => {
        if (rfqSessionFinishedByTimeout && rfqSessionNumberRef.current < RFQ_SESSIONS_IN_A_ROW) {
            rfqSessionNumberRef.current++;
            setIsTimeFinished(false);
            cancelRequest();
            refreshQuotes();
        }
    }, [rfqSessionFinishedByTimeout, refreshQuotes]);

    if (requestSide === "BOTH") {
        return null;
    }

    return (
        <Modal isOpen onClose={handleClose} style={FIXED_MODAL_STYLE}>
            <VStack minWidth="300px" maxWidth="300px" asCard>
                <TileHeader>
                    <StyledInstrumentIcon
                        assetCurrency={assetCurrency}
                        balanceCurrency={balanceCurrency}
                    />
                    <PBold>{instrumentName}</PBold>
                </TileHeader>
                <VStack spacing={8}>
                    <RequestTypeLabel requestSide={requestSide} />
                    <RequestInfo>
                        <RequestInfoRow>
                            <RequestInfoRowLabel>Liquidity providers</RequestInfoRowLabel>
                            <HStack spacing={2}>
                                {providersClientsIds.map((providerClientId) => (
                                    <ClientId
                                        id={providerClientId}
                                        key={providerClientId}
                                        isSmall
                                    />
                                ))}
                            </HStack>
                        </RequestInfoRow>
                        <RequestInfoRow>
                            <RequestInfoRowLabel>Size</RequestInfoRowLabel>
                            <span>
                                {toSize64FormattedStrByAsset(amount, assetCurrency)} {assetCurrency}
                            </span>
                        </RequestInfoRow>
                        <RequestInfoRow>
                            <RequestInfoRowLabel>Total volume</RequestInfoRowLabel>
                            <span>
                                {bestQuote !== null
                                    ? toSize64FormattedStrByAsset(bestQuote.price, balanceCurrency)
                                    : "~"}{" "}
                                {balanceCurrency}
                            </span>
                        </RequestInfoRow>
                        <RequestInfoRow>
                            <RequestInfoRowLabel>Total volume, $</RequestInfoRowLabel>
                            <span>
                                {usdPricesLoading
                                    ? "~"
                                    : toSize64FormattedStrByAsset(
                                          SIZE64.multiple2Size64Nums(
                                              priceObj[assetCurrency],
                                              amount,
                                          ),
                                          "USD",
                                      )}{" "}
                                USD
                            </span>
                        </RequestInfoRow>
                    </RequestInfo>
                    {isLoadingSuccessedQuote && !rfqSessionIsFinished && <WaitingForQuotes />}
                    {!isLoadingSuccessedQuote && !rfqSessionIsFinished && (
                        <TimerWithProgressBar
                            onTimeout={() => setIsTimeFinished(true)}
                            finishAt={quoteExpiration}
                        />
                    )}
                    {rfqSessionIsFinished && (
                        <RequestAgainButton
                            allQuotesRejected={allQuotesRejected}
                            onClick={() => {
                                rfqSessionNumberRef.current = 1;
                                setIsTimeFinished(false);
                                cancelRequest();
                                refreshQuotes();
                            }}
                        />
                    )}
                </VStack>
                <QuotesPanel
                    quotes={quotes}
                    requestQuotesCount={providersIds.length}
                    requestSide={requestSide}
                    onQuoteSelected={handleQuoteSelect}
                    assetCurrency={assetCurrency}
                    executingQuote={executingQuote}
                    actionIsInactive={rfqSessionIsFinished}
                />
                <CancelRequestButton onClick={handleClose} />
            </VStack>
        </Modal>
    );
};
