import { SEARCH_PARAMS } from "const";
import { useFilterState } from "hooks/useFilterState";
import { useSearchParamState } from "hooks/useSearchParamState";

const PAGE_FILTER_KEY = "trades_history";

export const usePagesState = (defaultValue: number[]) => {
    return useSearchParamState<typeof defaultValue>("page", defaultValue, {
        persist: false,
        parseValue: Number,
        isArray: true,
    });
};

export const useRangeState = (defaultValue: { startDate?: Date | null; endDate?: Date | null }) => {
    return useSearchParamState<typeof defaultValue>("range", defaultValue, {
        persist: false,
        parseValue: (value = "") => {
            try {
                const [startDate, endDate] = JSON.parse(value);
                return {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                };
            } catch (e) {
                return defaultValue;
            }
        },
        stringifyValue: (value) => {
            return JSON.stringify([value.startDate?.getTime(), value.endDate?.getTime()]);
        },
    });
};

export const useCounterpartiesState = (
    defaultValue: number[],
    availableValues: number[] | undefined,
) => {
    return useFilterState<typeof defaultValue>(PAGE_FILTER_KEY, "cp", defaultValue, {
        persist: false,
        parseValue: Number,
        isArray: true,
        availableValues,
    });
};

export const useInstrumentsState = (
    defaultValue: string[],
    availableValues: string[] | undefined,
) => {
    return useFilterState<typeof defaultValue>(PAGE_FILTER_KEY, "instrument", defaultValue, {
        persist: false,
        isArray: true,
        availableValues,
    });
};

export const useOrderTypeState = (
    defaultValue: string[],
    availableValues: string[] | undefined,
) => {
    return useFilterState<typeof defaultValue>(PAGE_FILTER_KEY, "orderType", defaultValue, {
        persist: false,
        isArray: true,
        availableValues,
    });
};

export const enum HistoryType {
    FirmBook = "firmBook",
    RFQ = "rfq",
}
export const useHistoryTypeState = () => {
    return useFilterState<HistoryType>(
        PAGE_FILTER_KEY,
        SEARCH_PARAMS.historyType,
        HistoryType.FirmBook,
    );
};
