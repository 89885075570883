import { VStack } from "@fm-frontend/uikit";
import { EmDash } from "const";
import { startOfDay, subMonths } from "date-fns";
import { useCounterparties, useInstruments } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { LS_VARIABLES, useLSState } from "hooks/useLSState";
import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { usePrimeBrokerViewType, useUserType } from "store/hooks";
import {
    historicalTradesFetcher,
    useDealHistoryParams,
    useHistoricalTrades,
} from "store/useHistoricalTrades";
import styled from "styled-components";
import { Deal, prepareDeal } from "types";
import { fmt } from "utils/format";
import { Content } from "./Content";
import { makeExportColumns } from "./exportHeaders";
import { GroupingOrder, Header, Range } from "./Header";
import {
    HistoryType,
    useCounterpartiesState,
    useInstrumentsState,
    useOrderTypeState,
    usePagesState,
    useRangeState,
} from "./hooks";
import { OrderTypeValues } from "./OrderTypeSelector";
import { groupTradeHistoriesByOrder, mapPreparedDealToTradingHistory } from "./utils";

const DEALS_LIMIT = 250;

const getDefaultRange = () => ({
    startDate: startOfDay(subMonths(new Date(), 1)),
    endDate: new Date(),
});

export const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const TradesHistory = ({
    setHistoryType,
}: {
    setHistoryType: (value: HistoryType, replace?: boolean) => void;
}) => {
    const primeBrokerViewType = usePrimeBrokerViewType();
    const { cpIds: counterparties, isLoading: isCounterpartiesLoading } = useCounterparties();
    const { instruments: instrumentList, isLoading: isInstrumentsLoading } = useInstruments();

    const instruments = useMemo(
        () => instrumentList.map((instrument) => instrument.instrumentName),
        [instrumentList],
    );

    const [pages, setPages, clearPages] = usePagesState([]);
    const [range, setRange, clearRange] = useRangeState(getDefaultRange());
    const [selectedCounterparties, setSelectedCounterparties, clearSelectedCounterparties] =
        useCounterpartiesState([], isCounterpartiesLoading ? undefined : counterparties);
    const [selectedInstruments, setSelectedInstruments, clearSelectedInstruments] =
        useInstrumentsState([], isInstrumentsLoading ? undefined : instruments);
    const [selectedOrderTypes, setSelectedOrderTypes, clearSelectedOrderTypes] = useOrderTypeState(
        [],
        OrderTypeValues,
    );

    const onHistoryTypeChange = (type: HistoryType) => {
        clearPages(true);
        clearRange(true);
        clearSelectedCounterparties(true);
        clearSelectedInstruments(true);
        clearSelectedOrderTypes(true);

        setHistoryType(type);
    };

    const [groupingOrder, setGroupingOrder] = useLSState<GroupingOrder>(
        LS_VARIABLES.TRADES_GROUPED_BY,
        GroupingOrder.ByOrder,
    );

    // TODO: move to separate Component and made request only after CP and Instruments loaded
    const { deals, isLoading, isValidating, mutate } = useHistoricalTrades({
        limit: DEALS_LIMIT,
        counterpartyIds:
            isCounterpartiesLoading || isInstrumentsLoading ? [] : selectedCounterparties,
        instrument: isCounterpartiesLoading || isInstrumentsLoading ? [] : selectedInstruments,
        orderTypes: selectedOrderTypes,
        from: range.startDate?.getTime(),
        to: range.endDate?.getTime(),
        till: pages[0],
    });
    const hasPrevPage = pages.length > 0;
    const hasNextPage = deals.length === DEALS_LIMIT;

    const preparedData = useMemo(
        () => deals.map((deal) => mapPreparedDealToTradingHistory(prepareDeal(deal))),
        [deals],
    );
    const groupedData = useMemo(
        () =>
            groupingOrder === GroupingOrder.ByOrder
                ? groupTradeHistoriesByOrder(preparedData)
                : preparedData,
        [groupingOrder, preparedData],
    );

    const { getCpName } = useCpInfoHelpers();
    const userType = useUserType();
    const requestParams = useDealHistoryParams({ limit: DEALS_LIMIT });
    const getExportData = useCallback(
        async ({ startDate, endDate }: Range) => {
            const exportColumns = makeExportColumns({ userType, primeBrokerViewType });
            let lastId = undefined;
            let exportData: Record<string, any>[] = [];

            while (true) {
                const pageData: Deal[] = await historicalTradesFetcher({
                    ...requestParams,
                    from: startDate?.getTime(),
                    to: endDate?.getTime(),
                    till: lastId,
                });
                const preparedPageData = pageData.map((deal) => prepareDeal(deal));
                const exportPageData = preparedPageData.map((preparedDeal) =>
                    exportColumns.reduce((row, column) => {
                        const value = column.accessor({
                            ...preparedDeal,
                            counterpartyName: getCpName(preparedDeal.counterpartyId, "full"),
                        });
                        const formattedValue = column.format
                            ? fmt(value ?? "", column.format).copyableValue
                            : value;

                        row[column.Header] = formattedValue === EmDash ? "-" : formattedValue;
                        return row;
                    }, {} as Record<string, any>),
                );
                exportData = exportData.concat(exportPageData);

                if (preparedPageData.length === 0) {
                    break;
                }
                if (lastId !== preparedPageData[preparedPageData.length - 1].dealId) {
                    lastId = preparedPageData[preparedPageData.length - 1].dealId;
                } else {
                    break;
                }
            }

            return exportData;
        },
        [requestParams, userType],
    );

    const handleRangeChange = useCallback(({ startDate, endDate }: Range) => {
        setPages([]);
        setRange({ startDate, endDate });
    }, []);
    const handleSelectedCounterpartiesChange = (newCounterparties: number[]) => {
        if (!isEqual(selectedCounterparties, newCounterparties)) {
            setPages([]);
            setSelectedCounterparties(newCounterparties);
        }
    };
    const handleSelectedInstrumentsChange = (newInstruments: string[]) => {
        if (!isEqual(selectedInstruments, newInstruments)) {
            setPages([]);
            setSelectedInstruments(newInstruments);
        }
    };
    const handleSelectedOrderTypeChange = (newOrderTypes: string[]) => {
        if (!isEqual(selectedOrderTypes, newOrderTypes)) {
            setPages([]);
            setSelectedOrderTypes(newOrderTypes);
        }
    };
    const handleRangeReset = useCallback(() => {
        setPages([]);
        setRange(getDefaultRange());
    }, []);
    const handleFilterReset = useCallback(() => {
        setPages([]);
        setSelectedCounterparties([]);
        setSelectedInstruments([]);
    }, []);
    const handleRefresh = useCallback(() => mutate(), [mutate]);
    const handlePrevClick = useCallback(() => {
        setPages(pages.slice(1));
    }, [pages]);
    const handleNextClick = useCallback(() => {
        const lastIndex = preparedData.length - 1;
        const lastId = preparedData[lastIndex].tradeId;
        setPages(lastId !== undefined ? [lastId, ...pages] : pages);
    }, [preparedData, pages]);

    return (
        <VStack margin={[0, 12, 0, 12]}>
            <CardContainer asCard>
                <Header
                    key={`${isCounterpartiesLoading}_${isInstrumentsLoading}`}
                    range={range}
                    groupingOrder={groupingOrder}
                    counterparties={counterparties}
                    instruments={instruments}
                    historyType={HistoryType.FirmBook}
                    onHistoryTypeChange={onHistoryTypeChange}
                    selectedCounterparties={selectedCounterparties}
                    selectedInstruments={selectedInstruments}
                    selectedOrderTypes={selectedOrderTypes}
                    isRefreshing={isValidating}
                    getExportData={getExportData}
                    onRangeChange={handleRangeChange}
                    onRangeReset={handleRangeReset}
                    onFilterReset={handleFilterReset}
                    onGroupingOrderChange={setGroupingOrder}
                    onSelectedCounterpartiesChange={handleSelectedCounterpartiesChange}
                    onSelectedInstrumentsChange={handleSelectedInstrumentsChange}
                    onSelectedOrderTypesChange={handleSelectedOrderTypeChange}
                    onRefresh={handleRefresh}
                />
                <Content
                    data={groupedData}
                    pageItemsCount={preparedData.length}
                    allItemsCount={DEALS_LIMIT * pages.length + preparedData.length}
                    isLoading={isLoading}
                    hasPrevPage={hasPrevPage}
                    hasNextPage={hasNextPage}
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                />
            </CardContainer>
        </VStack>
    );
};
