import { PayloadAction } from "@reduxjs/toolkit";
import { call, delay, put, select, take, takeLatest, SagaReturnType, all, race } from "@redux-saga/core/effects";
import {
    getNotifications,
    getUnreadNotificationsCount,
    markNotificationsAsRead,
    markAllNotificationsAsRead,
} from "services/notificationsService";
import { IRootState } from "store";
import { setIsTermsAndConditionsRequired, setOnboardingStages } from "store/onboardingSlice";
import {
    notificationsReadRequest,
    notificationsReadAllRequest,
    notificationsLoadMore,
    NotificationsReadRequestAction,
    notificationsAdd,
    notificationsUnreadCountSet,
    notificationsRead,
    notificationsReadAll,
    notificationsFetchRequest,
    NotificationsFetchRequestAction,
    notificationsFetchCountRequest,
} from "./notifications.store";
import { setIsActive } from "../app";

const FETCH_NOTIFICATIONS_DELAY = 20_000; // 20 sec
const FETCH_ELEMENTS_COUNT = 20;

function* fetchNotificationsCount() {
    try {
        const { count }: SagaReturnType<typeof getUnreadNotificationsCount> = yield call(getUnreadNotificationsCount);
        yield put(notificationsUnreadCountSet({ count }));
        return count;
    } catch (e) {
        console.error("Fetch notifications count error:", e);
        yield put(notificationsUnreadCountSet({ count: 0 }));
        return 0;
    }
}

function* fetchNotifications(page = 1)  {
    try {
        const { totalElements, elementsOnPage, currentPage,  data: notifications }: SagaReturnType<typeof getNotifications> = yield call(
            getNotifications,
            {
                page,
                elementsOnPage: FETCH_ELEMENTS_COUNT,
            },
        );
        yield put(notificationsAdd({
            totalElements,
            elementsOnPage,
            currentPage,
            notifications,
        }));
    } catch (e) {
        console.error("Fetch notifications error:", e);
    }
}

function* observeNotifications() {
    while (true) {
        const { isTermsAndConditionsRequired } = yield select((state) => state.onboarding.onboardingStages);

        if (isTermsAndConditionsRequired) {
            yield take([setOnboardingStages, setIsTermsAndConditionsRequired]);
        }

        const { unreadCount: currentUnreadCount } = yield select((state) => state.notifications);
        const newUnreadCount: SagaReturnType<typeof fetchNotificationsCount> = yield call(fetchNotificationsCount);

        if (currentUnreadCount !== newUnreadCount) {
            yield call(fetchNotifications);
        }

        yield race({
            delay: delay(FETCH_NOTIFICATIONS_DELAY),
            active: take(({ type, payload }: any) => {
                return type === setIsActive.toString() && payload === true;
            }),
        });
    }
}

function* handleNotificationsFetchRequest({ payload }: PayloadAction<NotificationsFetchRequestAction>) {
    const { page } = payload;

    yield call(fetchNotifications, page);
}

function* handleNotificationsFetchCountRequest() {
    yield call(fetchNotificationsCount);
}

function* handleNotificationsReadRequest({ payload }: PayloadAction<NotificationsReadRequestAction>) {
    const { ids } = payload;

    try {
        yield put(notificationsRead({ ids }));
        yield call(markNotificationsAsRead, { ids });
    } catch (e) {
        console.error("Mark notifications as `read` error:", e);
    }
}

function* handleNotificationsReadAllRequest() {
    try {
        yield put(notificationsReadAll());
        yield call(markAllNotificationsAsRead);
    } catch (e) {
        console.error("Mark all notifications as `read` error:", e);
    }
}

function* handleNotificationsLoadMore() {
    const { notifications } = yield select((state: IRootState) => state.notifications);
    const notificationsCount = notifications.length;
    const nextPage = Math.floor(notificationsCount / FETCH_ELEMENTS_COUNT) + 1;

    try {
        yield call(fetchNotifications, nextPage);
    } catch (e) {
        console.error("Load more` error:", e);
    }
}

export function* notificationsSaga() {
    yield all([
        call(observeNotifications),
        takeLatest(notificationsFetchRequest, handleNotificationsFetchRequest),
        takeLatest(notificationsFetchCountRequest, handleNotificationsFetchCountRequest),
        takeLatest(notificationsReadRequest, handleNotificationsReadRequest),
        takeLatest(notificationsReadAllRequest, handleNotificationsReadAllRequest),
        takeLatest(notificationsLoadMore, handleNotificationsLoadMore),
    ]);
}
