import {
    Flex,
    HStack,
    IconButton,
    Icons,
    PBold,
    PSmallBold,
    SimpleInput,
    VStack,
} from "@fm-frontend/uikit";
import { SIZE64 } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstrumentIcon } from "feature/assetsControl/components/InstrumentsTabContent/InstrumentIcon";
import { useRfqProvidersAndInstruments } from "feature/rfq/apiHooks/useRfqProvidersAndInstruments";
import { RfqRequestSide } from "feature/rfq/apiHooks/useRfqWs";
import { getNumericInputPattern } from "feature/trade/trading/TradingTile/utils/getNumericInputPattern";
import { handleNumberInputChange } from "feature/trade/trading/TradingTile/utils/handleNumberInputChange";
import { useInstruments } from "hooks";
import { useAssetFractionDigits } from "hooks/useAssetFractionDigits";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import { MouseEvent, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import { scrollToElement } from "utils";
import { AnySchema, array, number, object, string } from "yup";
import { ProvidersDropdown } from "./ProvidersDropdown";

const TileHeader = styled(HStack)`
    padding: 0 8px 0 12px;
    height: 32px;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
`;

const TileContent = styled(VStack)<{ $isActiveTile: boolean }>`
    padding: 0 8px 8px 8px;
    gap: 6px;
    opacity: ${({ $isActiveTile }) => ($isActiveTile ? 1 : 0.5)};
`;

const TileActions = styled(HStack)`
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const TileLayout = styled.div<{ $isActive: boolean }>`
    max-height: 167px;
    min-width: 278px;
    border-radius: 12px;
    ${(p) =>
        p.$isActive
            ? css`
                  box-shadow: 0px 0px 0px 1px ${p.theme.colors.ui12} inset,
                      0px 4px 16px 0px ${p.theme.colors.ui8};
              `
            : css`
                  border: 1px solid ${p.theme.colors.ui8};
              `}

    cursor: ${({ $isActive }) => ($isActive ? "default" : "pointer")};

    background-color: ${(p) => (p.$isActive ? p.theme.colors.uiWhite100 : "transparent")};

    :hover {
        background: ${(p) => !p.$isActive && p.theme.colors.ui4};
    }
`;

const TileButton = styled.button`
    all: unset;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56px;
    cursor: pointer;

    &:hover {
        background: ${(p) => p.theme.colors.ui8};
    }

    &:focus-visible {
        box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.brand12},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
    }
`;

const SellButton = styled(TileButton)<{ $isActiveTile: boolean }>`
    color: ${({ $isActiveTile, theme: { colors } }) =>
        $isActiveTile ? colors.negative100 : colors.negative32};
    border-right: 1px solid ${(p) => p.theme.colors.ui8};
    border-bottom-left-radius: 12px;
`;

const BuyButton = styled(TileButton)<{ $isActiveTile: boolean }>`
    color: ${({ $isActiveTile, theme: { colors } }) =>
        $isActiveTile ? colors.positive100 : colors.positive32};
    border-bottom-right-radius: 12px;
`;

const Wrapper = styled.div`
    max-height: 167px;
    oveflow: hidden;
    border-radius: 12px;
`;

const ErrorsContainer = styled(VStack)`
    margin-top: 6px;
    gap: 6px;
    align-items: center;
`;

const Error = styled(Flex)`
    width: fit-content;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui100};
    color: ${(p) => p.theme.colors.uiWhite100};
    align-items: center;
    justify-content: center;
    font-size: 12px;

    position: relative;
    z-index: 1;
`;

export type TilesSettings = Record<string, { defaultSelectedProvidersIds: number[] | null }>;

type TradingTileRFQProps = {
    instrumentName: string;
    assetCurrency: string;
    balanceCurrency: string;
    onDelete: (instrumentName: string) => void;
    isActive?: boolean;
    setActiveTile: (instrumentName: string) => void;
    defaultSelectedProvidersIds: number[] | null;
    onSelectedProvidersChange: (ids: number[]) => void;
    onRfqRequest: ({
        providersClientsIds,
        requestSide,
        amount,
    }: {
        providersClientsIds: number[];
        requestSide: RfqRequestSide;
        amount: bigint;
    }) => void;
};

type TileInputs = {
    providersClientsIds: number[];
    amount: string;
};

export const TradingTile = ({
    instrumentName,
    assetCurrency,
    balanceCurrency,
    onDelete,
    isActive = false,
    setActiveTile,
    defaultSelectedProvidersIds,
    onSelectedProvidersChange,
    onRfqRequest,
}: TradingTileRFQProps) => {
    const { rfqInstruments, rfqProviders } = useRfqProvidersAndInstruments();
    const providersClientsIds = rfqInstruments[instrumentName] ?? [];
    const { currencies } = useInstruments();
    const { isValidSize64StrBalanceStep } = useSize64AssetFormatHelpers();
    const elementRef = useRef<HTMLDivElement>(null);

    const yupSchema = useMemo(
        () =>
            object<Record<keyof TileInputs, AnySchema>>().shape({
                amount: string()
                    .required("Size is required")
                    .test({
                        name: "min",
                        exclusive: true,
                        message: "Size must be more that 0",
                        test: (amountStr) => Number(amountStr) > 0,
                    })
                    .test({
                        name: "max",
                        exclusive: true,
                        message: `Balance step of ${assetCurrency} is ${SIZE64.toSize64FormattedStr(
                            BigInt(currencies[assetCurrency].balanceStep),
                        )}`,
                        test: (amountStr) =>
                            isValidSize64StrBalanceStep(amountStr ?? "", assetCurrency),
                    })
                    .test({
                        name: "maxLength",
                        exclusive: true,
                        message: `Size whole number should be less or equal to 10 digits`,
                        test: (amountStr) =>
                            SIZE64.isValidSize64InputStr(amountStr ?? "") &&
                            String(SIZE64.fromSize64InputStr(amountStr ?? "")).length <= 18,
                    }),
                providersClientsIds: array()
                    .of(number().required())
                    .min(1, "At least one provider is required"),
            }),
        [isValidSize64StrBalanceStep],
    );
    const {
        control,
        watch,
        clearErrors,
        reset,
        getValues,
        formState: { errors },
        handleSubmit,
    } = useForm<TileInputs>({
        mode: "onChange",
        defaultValues: {
            providersClientsIds:
                defaultSelectedProvidersIds?.filter((providerId) =>
                    providersClientsIds.includes(providerId),
                ) ?? providersClientsIds,
            amount: "",
        },
        resolver: yupResolver(yupSchema),
    });

    useEffect(() => {
        const availableProvidersClientsIds = providersClientsIds;
        const currentSelectedProvidersClientsIds = getValues().providersClientsIds;
        const nextSelectedProvidersClientsIds =
            availableProvidersClientsIds.length - 1 === currentSelectedProvidersClientsIds.length
                ? availableProvidersClientsIds
                : currentSelectedProvidersClientsIds?.filter((providerId) =>
                      providersClientsIds.includes(providerId),
                  ) ?? availableProvidersClientsIds;

        reset({
            providersClientsIds: nextSelectedProvidersClientsIds,
        });
    }, [rfqProviders, reset, getValues, providersClientsIds]);

    useEffect(() => {
        if (isActive && elementRef.current) {
            scrollToElement(elementRef.current);
        }
        if (!isActive) {
            clearErrors();
        }
    }, [isActive]);

    const selectedProvidersClientsIds = watch("providersClientsIds");
    useEffect(() => {
        onSelectedProvidersChange(selectedProvidersClientsIds);
    }, [selectedProvidersClientsIds]);

    const { fractionDigits: amountAssetFractionDigits, placeholder: amountNumberPlaceholder } =
        useAssetFractionDigits(assetCurrency);

    const handleDelete = (ev: MouseEvent) => {
        ev.stopPropagation();
        onDelete(instrumentName);
    };

    return (
        <TileLayout
            ref={elementRef}
            onClick={() => setActiveTile(instrumentName)}
            $isActive={isActive}
        >
            <Wrapper>
                <TileHeader>
                    <InstrumentIcon
                        assetCurrency={assetCurrency}
                        balanceCurrency={balanceCurrency}
                    />
                    <PBold>{instrumentName}</PBold>
                    <IconButton variant="plain" Icon={Icons.X} onClick={handleDelete} />
                </TileHeader>
                <TileContent $isActiveTile={isActive}>
                    <ProvidersDropdown
                        control={control}
                        name="providersClientsIds"
                        providersClientIds={providersClientsIds}
                        error={errors.providersClientsIds && " "}
                    />
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SimpleInput
                                label={assetCurrency}
                                _size="medium"
                                placeholder={isActive ? amountNumberPlaceholder : "Size"}
                                error={errors.amount}
                                showError={false}
                                autoComplete="off"
                                {...field}
                                onChange={(event) => {
                                    handleNumberInputChange(event, field.onChange, {
                                        restrictionPattern:
                                            getNumericInputPattern(amountAssetFractionDigits),
                                    });
                                }}
                            />
                        )}
                        name="amount"
                    />
                </TileContent>
                <TileActions>
                    <SellButton
                        $isActiveTile={isActive}
                        onClick={handleSubmit((inputs: TileInputs) =>
                            onRfqRequest({
                                providersClientsIds: inputs.providersClientsIds,
                                requestSide: "SELL",
                                amount: SIZE64.fromSize64InputStr(inputs.amount),
                            }),
                        )}
                    >
                        <PSmallBold>RFQ</PSmallBold>
                        <PSmallBold>Sell {assetCurrency}</PSmallBold>
                    </SellButton>
                    <BuyButton
                        $isActiveTile={isActive}
                        onClick={handleSubmit((inputs: TileInputs) =>
                            onRfqRequest({
                                providersClientsIds: inputs.providersClientsIds,
                                requestSide: "BUY",
                                amount: SIZE64.fromSize64InputStr(inputs.amount),
                            }),
                        )}
                    >
                        <PSmallBold>RFQ</PSmallBold>
                        <PSmallBold>Buy {assetCurrency}</PSmallBold>
                    </BuyButton>
                </TileActions>
            </Wrapper>
            <ErrorsContainer>
                {[errors.providersClientsIds?.message, errors.amount?.message]
                    .filter((errMsg) => Boolean(errMsg))
                    .map((errMsg) => (
                        <Error key={errMsg}>{errMsg}</Error>
                    ))}
            </ErrorsContainer>
        </TileLayout>
    );
};
