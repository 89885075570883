import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useMemo } from "react";
import useSWR from "swr";
import { PreparedDeal, prepareDeal } from "types";
import { displayError } from "utils";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "./hooks";

export const DEAL_HISTORY_API_METHOD = "dealHistory";

export const useLastTradesSWRKey = (limit: number, authorizedClientId?: AuthorizedClientId) => {
    const sideKey = usePrimeBrokerViewType();

    return `${sideKey}-${limit}-${authorizedClientId}`;
};

export const useDealHistoryParams = (limit?: number, authorizedClientId?: AuthorizedClientId) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const side = isPrimeBrokerUser ? primeBrokerViewType : "counterparties";

    return useMemo(
        () => ({
            limit,
            filter: side === "counterparties" ? "external" : "subaccounts",
            counterpartyIds: authorizedClientId ? [authorizedClientId] : undefined,
        }),
        [side, limit, authorizedClientId],
    );
};

export const useLastTrades = (limit: number, authorizedClientId?: AuthorizedClientId) => {
    const params = useDealHistoryParams(limit, authorizedClientId);

    const lastTradesSWRKey = useLastTradesSWRKey(limit, authorizedClientId);
    const fetcher = () =>
        post(DEAL_HISTORY_API_METHOD, params).then((data) =>
            Array.isArray(data) ? data.map(prepareDeal) : [],
        );

    const { data, isLoading, isValidating, mutate } = useSWR(lastTradesSWRKey, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as PreparedDeal[],
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    const trades: PreparedDeal[] = useMemo(() => {
        return data
            .concat()
            .sort((a, b) => b.dealMoment - a.dealMoment)
            .filter((_: unknown, idx: number) => idx < limit);
    }, [data, limit]);

    return {
        trades,
        isLoading,
        isValidating,
        mutate,
    };
};
