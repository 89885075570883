import { Center, P } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { ROUTES } from "feature/app/router";
import { useIsAuthorizedTradingAvailable } from "feature/trade/hooks/useIsAuthorizedTradingAvailable";
import React from "react";
import { NavLink } from "react-router-dom";
import { useIsSubaccountUser } from "store/hooks";
import styled from "styled-components";

const StyledNavLink = styled(NavLink)`
    color: ${(p) => p.theme.colors.brand100};
`;

const Description = styled(P)`
    text-align: center;
    width: 60%;
`;

export const EmptyTradingBook: React.FC<{ selectedInstrument?: string }> = ({
    selectedInstrument,
}) => {
    const isSubaccountUser = useIsSubaccountUser();
    const isAuthorizedTradingAvailable = useIsAuthorizedTradingAvailable();

    if (isSubaccountUser || isAuthorizedTradingAvailable) {
        return (
            <Center flex={1}>
                <EmptySearch
                    description={
                        <Description>
                            To see the quotes, ensure that your Master has set the CP limit in Risk
                            Management and is streaming {selectedInstrument} quotes
                        </Description>
                    }
                />
            </Center>
        );
    }

    return (
        <Center flex={1}>
            <EmptySearch
                description={
                    <Description>
                        To see the quotes, set CP limits in{" "}
                        <StyledNavLink to={ROUTES.riskManagement}>Risk management</StyledNavLink>{" "}
                        and ensure that your LPs stream {selectedInstrument} quotes.
                    </Description>
                }
            />
        </Center>
    );
};
