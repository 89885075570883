import { SIZE_VALUE_MULTIPLICATOR } from "@fm-frontend/utils";
import { TradingSide } from "feature/trade/trading/types";
import { NOT_ENOUGH_LIQUIDITY } from "feature/trade/trading/utils";

export const calculateTotalVolumeOrSize = ({
    sizeOrVolume,
    tradingSide,
    buyPrice,
    sellPrice,
}: {
    sizeOrVolume: bigint | null;
    tradingSide: TradingSide;
    buyPrice: bigint | null;
    sellPrice: bigint | null;
}) => {
    if ((buyPrice === null && sellPrice === null) || sizeOrVolume === null) {
        return null;
    }

    if (buyPrice === NOT_ENOUGH_LIQUIDITY || sellPrice === NOT_ENOUGH_LIQUIDITY) {
        return NOT_ENOUGH_LIQUIDITY;
    }

    let midPrice = 0n;

    if (buyPrice === null && sellPrice !== null) {
        midPrice = sellPrice;
    }
    if (sellPrice === null && buyPrice !== null) {
        midPrice = buyPrice;
    }
    if (buyPrice !== null && sellPrice !== null) {
        midPrice = (buyPrice + sellPrice) / 2n;
    }

    switch (tradingSide) {
        case "volume": {
            if (midPrice === 0n) {
                return NOT_ENOUGH_LIQUIDITY;
            }

            return (sizeOrVolume * BigInt(SIZE_VALUE_MULTIPLICATOR)) / midPrice;
        }
        case "size": {
            return (sizeOrVolume * midPrice) / BigInt(SIZE_VALUE_MULTIPLICATOR);
        }
        default:
            return NOT_ENOUGH_LIQUIDITY;
    }
};
