import { Flex, IconButton, Icons, Table, Tooltip, VStack } from "@fm-frontend/uikit";
import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { AssetInfo } from "components/AssetInfo";
import { useMemo } from "react";
import { fmt } from "utils/format";
import { AutoOverflow } from "../styled";
import { WithdrawalRequest } from "./types";

interface WithdrawalRequestsTableProps {
    data: WithdrawalRequest[];
    isLoading: boolean;
    onCancelRequestClick: (withdrawal: WithdrawalRequest) => void;
}

export const WithdrawalRequestsTable = ({
    data,
    isLoading,
    onCancelRequestClick,
}: WithdrawalRequestsTableProps) => {
    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<WithdrawalRequest>();

        return [
            columnHelper.accessor((row) => row, {
                id: "asset",
                cell: (rowData) => {
                    const { asset, network } = rowData.getValue();

                    return <AssetInfo asset={asset} network={network} />;
                },
                header: () => <>Asset</>,
                meta: {
                    cellStyleProps: {
                        padding: 0,
                        width: "100px",
                    },
                    headerStyleProps: {
                        padding: "0 0 8px 0",
                    },
                },
            }),
            columnHelper.accessor((row) => row.amount, {
                id: "amount",
                cell: (amount) => {
                    const value = amount.getValue();

                    return value
                        ? fmt(value, {
                              significantDigits: 8,
                              type: "size64",
                          }).formattedValue
                        : "All available";
                },
                header: () => <>Amount</>,
                meta: {
                    cellStyleProps: {
                        width: "172px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.isFeePaidByRecipient, {
                id: "feePayer",
                cell: (isFeePaidByRecipient) => {
                    const value = isFeePaidByRecipient.getValue();

                    return value ? "by Recipient" : "by Sender";
                },
                header: () => "Fee",
                meta: {
                    cellStyleProps: {
                        width: "120px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.comment, {
                id: "comment",
                cell: (comment) => {
                    const value = comment.getValue();

                    return value;
                },
                header: () => <>Comment</>,
            }),
            columnHelper.display({
                id: "actions",
                cell: (props) => {
                    const withdrawal = props.row.original;

                    return (
                        <Flex spacing={8} justifyContent="end">
                            <Tooltip content="Delete">
                                <IconButton
                                    variant="basic"
                                    Icon={Icons.Bin}
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        onCancelRequestClick(withdrawal);
                                    }}
                                />
                            </Tooltip>
                        </Flex>
                    );
                },
                meta: {
                    cellStyleProps: {
                        width: "120px",
                        textAlign: "right",
                        padding: "0 0 0 12px",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                        padding: "0 0 8px 12px",
                    },
                },
            }),
        ];
    }, []);

    const tableOptions = {
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false,
    };

    return (
        <VStack width="100%" paddingX={12} overflow="auto">
            <AutoOverflow>
                <Table tableOptions={tableOptions} isLoading={isLoading} />
            </AutoOverflow>
        </VStack>
    );
};
