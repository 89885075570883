import { authWSRequest } from "api";
import { Socket } from "feature/app/socket";
import { getSession, SessionResponse } from "services/authService";
import { displayError } from "utils";
import { parse, stringify } from "utils/json";

const RETRIES_TIMEOUTS_MS = [0, 0, 1, 2, 3, 5].map((t) => t * 1000);

type Listener<TMessageIn> = ({ lastMessage }: { lastMessage: TMessageIn }) => void;
export class WebSocketService<TMessageIn extends { event: string }, TMessageOut> {
    #wsUrl: string | null = null;

    #isConnectedMessage: (message: TMessageIn) => boolean;

    #isAuthorizedMessage: (message: TMessageIn) => boolean;

    #listeners = new Set<Listener<TMessageIn>>();

    #socket: null | Socket = null;

    #isRefreshingSocket = false;

    #isSocketReady = false;

    #messagesOutQueue: TMessageOut[] = [];

    #retriesCount = 0;

    constructor(
        wsUrl: string,
        isConnectedMessage: (message: TMessageIn) => boolean,
        isAuthorizedMessage: (message: TMessageIn) => boolean,
    ) {
        this.#wsUrl = wsUrl;
        this.#isConnectedMessage = isConnectedMessage;
        this.#isAuthorizedMessage = isAuthorizedMessage;
    }

    #visibilityChangeListener = () => {
        if (!document.hidden) {
            if (this.#listeners.size > 0) {
                this.#tryRefreshSocket();
            }
        }
    };

    #killSocket() {
        this.#socket?.close();
        this.#socket = null;
        this.#isSocketReady = false;
    }

    #tryRefreshSocket() {
        if (this.#isRefreshingSocket || this.#isSocketReady || this.#listeners.size === 0) {
            return;
        }
        this.#isRefreshingSocket = true;
        setTimeout(() => {
            console.log(`${this.#wsUrl} WS is connecting`);
            this.#retriesCount++;
            this.#socket = new Socket(this.#wsUrl!, ({ data }: { data: string }) => {
                const lastMessage = parse(data) as TMessageIn;
                if (this.#isConnectedMessage(lastMessage)) {
                    console.log(`${this.#wsUrl} WS is connected`);
                    getSession().then((userSession: SessionResponse) => {
                        const key = { token: userSession.accessToken?.toString() };
                        const authMessage = {
                            ...authWSRequest(JSON.stringify(key)),
                            event: "AUTH",
                        };
                        this.#socket?.send(stringify(authMessage));
                    });
                    return;
                }
                if (this.#isAuthorizedMessage(lastMessage)) {
                    console.log(`${this.#wsUrl} WS is authorized and ready`);
                    this.#retriesCount = 0;
                    this.#isRefreshingSocket = false;
                    this.#isSocketReady = true;
                    this.#messagesOutQueue.forEach((message) => {
                        this.sendMessage(message);
                    });
                    return;
                }
                Array.from(this.#listeners).forEach((listener) => {
                    listener({ lastMessage });
                });
            });
            this.#socket.onClose = (ev) => {
                console.log(`${this.#wsUrl} WS is closed. Code:`, ev.code);
                if (this.#retriesCount >= 4) {
                    displayError(
                        `${this.#wsUrl} WS was closed ${this.#retriesCount} times in a row. Code: ${
                            ev.code
                        }`,
                    );
                }
                this.#isRefreshingSocket = false;
                this.#killSocket();
                if (!document.hidden) {
                    this.#tryRefreshSocket();
                } else {
                    this.#retriesCount = 0;
                }
            };
            this.#socket.init();
        }, RETRIES_TIMEOUTS_MS[this.#retriesCount - 1] ?? RETRIES_TIMEOUTS_MS.at(-1));
    }

    sendMessage(message: TMessageOut) {
        if (this.#listeners.size === 0) {
            throw new Error(
                `${this.#wsUrl}: you must have at least one subscriber before sending message`,
            );
        }
        this.#tryRefreshSocket();
        if (!this.#isSocketReady) {
            this.#messagesOutQueue.push(message);
            return;
        }
        this.#socket?.send(stringify(message));
    }

    subscribe(listener: Listener<TMessageIn>) {
        if (this.#listeners.size === 0) {
            document.addEventListener("visibilitychange", this.#visibilityChangeListener);
        }
        this.#listeners.add(listener);
        this.#tryRefreshSocket();

        return () => {
            this.#listeners.delete(listener);
            if (this.#listeners.size === 0) {
                document.removeEventListener("visibilitychange", this.#visibilityChangeListener);
                this.#killSocket();
            }
        };
    }
}
