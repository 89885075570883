import {
    ClientId,
    Flex,
    HStack,
    IconButton,
    Icons,
    InfiniteTable,
    P,
    PBold,
    PSmall,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { AssetInfo } from "components/AssetInfo";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { TransactionLabel } from "feature/settlements/components/TransactionLabel";
import { Amount, CommentCell } from "feature/settlements/SettlementsTable";
import { Status } from "feature/settlements/Status";
import { Settlement } from "feature/settlements/types";
import { useMemo } from "react";
import styled from "styled-components";
import EfxTypes from "utils/EfxTypes";
import { fmt, getAbsoluteValue, toLongDateString } from "utils/format";
import { CreatedTransactionFormData } from "./modals/CreateTransactionModal";

interface TransactionsTableProps {
    data: Settlement[];
    onAutoCommitTransactionClick: (transaction: CreatedTransactionFormData) => void;
    onCommitDepositClick: (deposit: Settlement) => void;
    onDeleteSettlementClick: (settlement: Settlement) => void;
    onCreateTransactionByRequest: (request: Settlement) => void;
    isLoading: boolean;
}

const FeePayer = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const PAYER_TITLES = {
    recipient: "by Recipient",
    sender: "by Sender",
};

export const TransactionsTable = ({
    data,
    onCommitDepositClick,
    onCreateTransactionByRequest,
    onAutoCommitTransactionClick,
    onDeleteSettlementClick,
    isLoading,
}: TransactionsTableProps) => {
    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<Settlement>();

        return [
            columnHelper.accessor((row) => row, {
                id: "status",
                cell: (rowData) => {
                    const { status, type } = rowData.getValue();
                    return <Status value={status} type={type} />;
                },
                header: () => "Status",
                meta: {
                    cellStyleProps: {
                        width: "87px",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "type",
                cell: (rowData) => {
                    const { type, status } = rowData.getValue();
                    if (status === "request") {
                        return type === "in" ? "In" : "Out";
                    }
                    return type === "in" ? "Deposit" : "Withdrawal";
                },
                header: () => <>Type</>,
                meta: {
                    cellStyleProps: {
                        width: "92px",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "name",
                cell: (rowData) => {
                    const { cpId, cpName } = rowData.getValue();
                    return (
                        <HStack spacing={4}>
                            <PBold>{cpName}</PBold> <ClientId id={String(cpId)} />
                        </HStack>
                    );
                },
                header: () => <>Sub-account</>,
                meta: {
                    cellStyleProps: {
                        width: "200px",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "asset",
                cell: (rowData) => {
                    const { asset, network } = rowData.getValue();
                    return <AssetInfo currency={asset} asset={asset} network={network} />;
                },
                header: () => "Asset",
                meta: {
                    cellStyleProps: {
                        width: "150px",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "amount",
                cell: (rowData) => {
                    const { amount, asset } = rowData.getValue();

                    return <Amount amount={amount} asset={asset} />;
                },
                header: () => "Amount",
                meta: {
                    cellStyleProps: {
                        width: "150px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.fee, {
                id: "fee",
                cell: (fee) => {
                    const value = fee.getValue();
                    if (!value) {
                        return null;
                    }
                    return (
                        <VStack>
                            {value.size && (
                                <PSmall>
                                    {
                                        fmt(value.size, {
                                            significantDigits: 8,
                                            type: "size64",
                                        }).formattedValue
                                    }
                                </PSmall>
                            )}
                            <FeePayer>{PAYER_TITLES[value.payer]}</FeePayer>
                        </VStack>
                    );
                },
                header: () => "Fee",
                meta: {
                    cellStyleProps: {
                        width: "130px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.txId, {
                id: "txId",
                cell: (txId) => {
                    const value = txId.getValue();

                    return <TransactionLabel txId={value} />;
                },
                header: "TX ID",
                meta: {
                    cellStyleProps: {
                        width: "110px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                        padding: "0 18px 8px 0",
                    },
                },
            }),
            columnHelper.accessor((row) => row.comment, {
                id: "comment",
                cell: (comment) => <CommentCell>{comment.getValue()}</CommentCell>,
                header: "Comment",
                meta: {
                    cellStyleProps: {
                        textAlign: "right",
                        minWidth: "150px",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.lastActionAt, {
                id: "lastAction",
                cell: (created) => <P>{toLongDateString(created.getValue())}</P>,
                header: () => "Last action",
                meta: {
                    cellStyleProps: {
                        width: "180px",
                    },
                },
            }),
            columnHelper.display({
                id: "actions",
                cell: (props) => {
                    const settlement = props.row.original;
                    const hasCreateTransactionByRequestButton =
                        settlement.status === "request" && settlement.type === "in";
                    const hasCommitButton = settlement.status === "received";
                    const hasAutoCommitButton =
                        (settlement.type === "out" || settlement.isCreatedByRecipient) &&
                        settlement.status === "created";
                    const hasDeleteButton = hasAutoCommitButton || settlement.status === "request";

                    return (
                        <Flex spacing={8} justifyContent="end">
                            {hasCreateTransactionByRequestButton && (
                                <Tooltip content="Create transaction">
                                    <IconButton
                                        variant="primary"
                                        Icon={Icons.ArrowEnter}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onCreateTransactionByRequest(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasCommitButton && (
                                <Tooltip content="Commit deposit">
                                    <IconButton
                                        variant="primary"
                                        Icon={Icons.Checkmark}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onCommitDepositClick(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasAutoCommitButton && (
                                <Tooltip content="Commit">
                                    <IconButton
                                        variant="primary"
                                        Icon={Icons.ArrowUp}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            const {
                                                id,
                                                cpId: subaccountId,
                                                asset,
                                                network,
                                                amount,
                                                comment,
                                                fee,
                                                type,
                                            } = settlement;
                                            onAutoCommitTransactionClick({
                                                id,
                                                subaccountId,
                                                asset,
                                                hasNetworks: false,
                                                network,
                                                amount: EfxTypes.formatValue(
                                                    getAbsoluteValue(amount),
                                                    "size64",
                                                ),
                                                comment,
                                                isFeePaidByRecipient: fee?.payer === "recipient",
                                                fee:
                                                    fee?.size === undefined
                                                        ? undefined
                                                        : EfxTypes.formatValue(
                                                              getAbsoluteValue(fee.size),
                                                              "size64",
                                                          ),
                                                type: type === "in" ? "deposit" : "withdrawal",
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasDeleteButton && (
                                <Tooltip content="Delete">
                                    <IconButton
                                        variant="basic"
                                        Icon={Icons.Bin}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onDeleteSettlementClick(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
                meta: {
                    cellStyleProps: {
                        width: "80px",
                        textAlign: "right",
                        padding: "0 12px 0 12px",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                        padding: "0 12px 8px 12px",
                    },
                },
            }),
        ];
    }, []);

    const tableOptions = {
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false,
        autoResetPageIndex: false,
    };

    return (
        <BottomFixTableContainer>
            <InfiniteTable tableOptions={tableOptions} isLoading={isLoading} />
        </BottomFixTableContainer>
    );
};
