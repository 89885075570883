import { Accordion, HStack, PSmall, Tab, TabContext, TabList } from "@fm-frontend/uikit";
import { SIZE64 } from "@fm-frontend/utils";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useAuthorizedClientInfo } from "feature/trade/hooks/useAuthorizedClientInfo";
import { useState } from "react";
import { GroupBy, useAuthorizedPositionsTableData } from "store/usePositionsGrouped";
import styled from "styled-components";
import { AuthorizedPositionsAccordionContent } from "./AuthorizedPositionsAccordionContent";

const Caption = styled.span`
    color: ${(p) => p.theme.colors.ui72};
    text-transform: uppercase;
`;

const TabsContainer = styled.div`
    max-width: 120px;
`;

const PositionsAccordionHeader = styled(HStack)`
    gap: 12px;
    align-items: center;
    ${Caption} {
        flex: 1;
    }
`;

export const AuthorizedPositionsAccordion = ({
    authorizedClientId,
}: {
    authorizedClientId: AuthorizedClientId;
}) => {
    const authorizedClientInfo = useAuthorizedClientInfo(authorizedClientId);
    const [groupBy, setGroupBy] = useState<GroupBy>("counterparty");
    const { totalUSD, groupedPositionsData, isLoading } = useAuthorizedPositionsTableData({
        groupBy,
        includeSettlementOrders: true,
        authorizedClientId,
    });
    const [expanded, setExpanded] = useState(true);

    const authorizedTradingTitle = authorizedClientInfo
        ? `${authorizedClientInfo.username}'s positions`
        : "";

    const header = (
        <PositionsAccordionHeader>
            <Caption>{authorizedTradingTitle}</Caption>
            {expanded && (
                <TabsContainer
                    onClick={(ev) => {
                        ev.stopPropagation();
                    }}
                >
                    <TabContext value={groupBy} handleClick={setGroupBy}>
                        <TabList size="small">
                            <Tab title="CPs" value="counterparty" key="counterparty" />
                            <Tab title="Assets" value="asset" key="asset" />
                        </TabList>
                    </TabContext>
                </TabsContainer>
            )}
            <PSmall>Total: ${SIZE64.toSize64FormattedStr(totalUSD, 2)}</PSmall>
        </PositionsAccordionHeader>
    );

    return (
        <Accordion
            header={header}
            defaultExpanded={expanded}
            onExpandedChange={setExpanded}
            content={
                <AuthorizedPositionsAccordionContent
                    groupBy={groupBy}
                    groupedPositionsData={groupedPositionsData}
                    isLoading={isLoading}
                />
            }
        />
    );
};
