import { format8FractionDigits } from "./formatters";

const isBigInt = (num: bigint) => {
    if (typeof num !== "bigint") {
        throw new TypeError(`${num} is not BigInt!`);
    }
};

const size64InputStrFormat = /^(\-)?[0-9]+(\.[0-9]*)?$/;
export const SIZE64 = {
    toSize64FormattedStr(
        size64Num: bigint,
        fixedFractionDigits?: number,
        minFixedFractionDigits?: number,
    ) {
        isBigInt(size64Num);
        if (fixedFractionDigits === undefined) {
            return format8FractionDigits(size64Num, 8, minFixedFractionDigits ?? 0);
        }
        return format8FractionDigits(size64Num, fixedFractionDigits, minFixedFractionDigits);
    },
    toSize64InputStr(size64Num?: bigint) {
        if (size64Num === undefined) {
            return "";
        }
        isBigInt(size64Num);
        if (size64Num === 0n) {
            return "0";
        }
        const isNegative = size64Num < 0n;
        const absoluteNumStr = String(isNegative ? -size64Num : size64Num);
        const remainderStr = absoluteNumStr.slice(-8);
        const trailingZeroIndex = [...remainderStr].findLastIndex((char) => char !== "0");
        const normalizedRemainderStr = remainderStr.slice(0, trailingZeroIndex + 1);
        const quotient = absoluteNumStr.slice(0, -8);
        return `${isNegative ? "-" : ""}${quotient || "0"}${
            normalizedRemainderStr.length > 0 ? `.${normalizedRemainderStr}` : ""
        }`;
    },
    fromSize64InputStr(size64InputStr: string) {
        const normalizedSize64Str = size64InputStr.trim();
        if (normalizedSize64Str === "") {
            throw new TypeError("Empty string is not a number");
        }
        const [quotient = "", remainder = ""] = normalizedSize64Str.split(/[.]/);

        return (
            BigInt(quotient) * BigInt(1e8) +
            BigInt(Number(`${remainder}000000000`.slice(0, 9)) + 5) / 10n
        );
    },
    isValidSize64InputStr(size64InputStr: string) {
        const normalizedSize64Str = size64InputStr.trim();
        if (normalizedSize64Str === "" || !size64InputStrFormat.test(size64InputStr)) {
            return false;
        }
        const [, remainder = ""] = normalizedSize64Str.split(/[.]/);
        if (remainder.length > 8 && !/^0+$/.test(remainder.slice(8))) {
            return false;
        }
        return true;
    },
    multiple2Size64Nums(size64Num1: bigint, size64Num2: bigint) {
        isBigInt(size64Num1);
        isBigInt(size64Num2);

        const product = size64Num1 * size64Num2;
        const firstCutDigit = Number(String(product).at(-8) ?? "0");
        return (size64Num1 * size64Num2) / BigInt(1e8) + (firstCutDigit >= 5 ? 1n : 0n);
    },
};
