import { useGA4Context } from "@fm-frontend/utils";
import { AppLayout } from "App";
import { Providers } from "feature/onboarding";
import { useIsProvidersSectionAvailable } from "hooks/useIsProvidersSectionAvailable";
import { Agreement } from "pages/agreement/Agreement";
import { Keys } from "pages/api/keys";
import { AssetsControl } from "pages/assetsControl/AssetsControl";
import { PageSettlementsTransactions } from "pages/history/settlements";
import { Trades } from "pages/history/trades";
import { RiskManagement } from "pages/riskManagement/RiskManagement";
import { SettingsReferrals } from "pages/settings/referrals";
import { SettingsNotifications } from "pages/settings/SettingsNotifications";
import { SettingsProfile } from "pages/settings/SettingsProfile";
import { SettingsSecurity } from "pages/settings/SettingsSecurity";
import { SettingsUsersManagement } from "pages/settings/SettingsUsersManagement";
import { AddressesPage } from "pages/settlement/addresses";
import { SettlementsPage } from "pages/settlements/Settlements";
import { SubaccountsViewTransactionsPage } from "pages/specificPrimeBrokerPages/SubaccountsViewTransactionsPage";
import { RequestsPage } from "pages/specificSubaccountPages/RequestsPage";
import { TransactionsPage } from "pages/specificSubaccountPages/TransactionsPage";
import { Subaccounts } from "pages/subaccounts/Subaccounts";
import { TermsAndConditions } from "pages/termsAndConditions/TermsAndConditions";
import { PageOrders } from "pages/trade/orders";
import { PagePositions } from "pages/trade/positions";
import { PageTrading } from "pages/trade/trading";
import { memo, useEffect, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useHasMakerRoleUser, useIsPrimeBrokerUser, useIsSubaccountUser } from "store/hooks";
import { SWRConfig } from "swr";
import { ROUTES } from "./routes";

const RedirectToAccount = () => <Redirect to="/settings/account" />;
const RedirectToTrading = () => {
    return <Redirect to={ROUTES.trading} />;
};
const RedirectToRiskManagement = () => {
    return <Redirect to={ROUTES.riskManagement} />;
};
const RedirectToSubaccountTransactions = () => {
    return <Redirect to={{
        pathname: "/subaccountTransactions",
        search: window.location.search,
    }} />;
};
const RedirectToAssetsControl = () => <Redirect to={ROUTES.assetsControl} />;

export const UserRouter = memo(() => {
    const hasMakerRoleUser = useHasMakerRoleUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isSubaccountUser = useIsSubaccountUser();

    const { logPageView } = useGA4Context();
    useEffect(() => logPageView(), [logPageView]);
    const config = useMemo(() => ({ provider: () => new Map() }), []);

    const defaultPath = isPrimeBrokerUser ? ROUTES.positions : ROUTES.trading;
    const isProvidersSectionAvailable = useIsProvidersSectionAvailable();

    return (
        <SWRConfig value={config}>
            <Switch>
                <Route
                    exact
                    path={`${ROUTES.termsAndConditions}/:token?`}
                    component={TermsAndConditions}
                />
                <AppLayout>
                    <Switch>
                        <Route path={ROUTES.riskManagement} component={RiskManagement} />
                        <Route path="/limits" component={RedirectToRiskManagement} />
                        {hasMakerRoleUser && <Route path="/orders" component={PageOrders} />}
                        <Route path={ROUTES.trading} component={PageTrading} />
                        {isSubaccountUser && <Route path="/subaccountRequests" component={RequestsPage} />}
                        {isSubaccountUser && ([
                            <Route key="sub-transactions-redirect" path="/transactions" component={RedirectToSubaccountTransactions } />,
                            <Route key="sub-transactions-route" path="/subaccountTransactions" component={TransactionsPage} />,
                        ])}
                        <Route path="/books/:instrument?" component={RedirectToTrading} />
                        <Route path={ROUTES.historyTrades} component={Trades} />
                        <Route path={ROUTES.positions} component={PagePositions} />
                        {isPrimeBrokerUser && (
                            <Route
                                path="/subaccountsViewTransactions"
                                component={SubaccountsViewTransactionsPage}
                            />
                        )}
                        {isPrimeBrokerUser && <Route path="/subaccounts" component={Subaccounts} />}
                        {!isSubaccountUser && (
                            <Route path="/transactions" component={SettlementsPage} />
                        )}
                        <Route path={ROUTES.assets} component={RedirectToAssetsControl} />
                        <Route path={`${ROUTES.assetsControl}/:tab?`} component={AssetsControl} />
                        <Route
                            path={`${ROUTES.addresses}/:side?/:type?`}
                            component={AddressesPage}
                        />
                        <Route path="/settlements" component={PageSettlementsTransactions} />
                        <Route path="/keys" component={Keys} />
                        <Route path="/settings" exact component={RedirectToAccount} />
                        <Route path="/settings/security" component={SettingsSecurity} />
                        <Route
                            path="/settings/usersmanagement"
                            component={SettingsUsersManagement}
                        />
                        <Route path="/settings/notifications" component={SettingsNotifications} />
                        <Route path="/settings/profile" component={SettingsProfile} />
                        <Route path="/agreement" component={Agreement} />
                        {!isSubaccountUser && (
                            <Route path="/settings/referrals" component={SettingsReferrals} />
                        )}
                        {isProvidersSectionAvailable && (
                            <Route path="/providers" component={Providers} />
                        )}
                        <Redirect to={defaultPath} />
                    </Switch>
                </AppLayout>
            </Switch>
        </SWRConfig>
    );
});
UserRouter.displayName = "UserRouter";

export default UserRouter;
