import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { VFC } from "react";
import { HistoryType, useHistoryTypeState } from "./hooks";
import { RfqHistory } from "./RfqHistory";
import { TradesHistory } from "./TradesHistory";

export const TradesHistoryPageContent: VFC = () => {
    const [historyType, setHistoryType] = useHistoryTypeState();

    if (historyType === HistoryType.FirmBook) {
        return <TradesHistory setHistoryType={setHistoryType} />;
    }

    if (historyType === HistoryType.RFQ) {
        return <RfqHistory setHistoryType={setHistoryType} />;
    }

    return <EmptySearch />;
};
