import { Flex, VStack } from "@fm-frontend/uikit";
import { useAuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useAuthorizedDealsHistory } from "feature/trade/hooks/useAuthorizedDealsHistory";
import { TRADING_LAST_TRADES_LIMIT } from "feature/trade/trading/utils";
import styled from "styled-components";
import { AuthorizedLastFirmBookTable } from "./AuthorizedLastFirmBookTable";

const Container = styled(Flex)`
    overflow: auto;
    padding: 0 1px 1px 1px;
    border-radius: 16px;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const AuthorizedLastFirmBookAccordionContent = () => {
    const { authorizedClientId } = useAuthorizedClientId();
    const { data: trades = [], isLoading } = useAuthorizedDealsHistory(
        TRADING_LAST_TRADES_LIMIT,
        authorizedClientId
    );

    return (
        <Container spacing={8}>
            <CardContainer flex={1} asCard>
                <AuthorizedLastFirmBookTable trades={trades} isLoading={isLoading} />
            </CardContainer>
        </Container>
    );
};
