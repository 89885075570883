import { call, put, select, takeEvery } from "@redux-saga/core/effects";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { createNotification, setIsLoadingApp } from "feature/app";
import { IRootState } from "store";

export type RfqSettingsState = {
    rfqTradingEnabled: boolean;
    rfqViewingEnabled: boolean;
    favoriteInstruments: string[];
};

const rfqSettingsInitialState: RfqSettingsState = {
    rfqTradingEnabled: false,
    rfqViewingEnabled: false,
    favoriteInstruments: EMPTY_ARRAY as string[],
};

export const rfqSettingSlice = createSlice({
    name: "rfqSettings",
    initialState: rfqSettingsInitialState,
    reducers: {
        setRfqSettings(
            state,
            { payload }: PayloadAction<{ tradingEnabled: boolean; viewingEnabled: boolean }>,
        ) {
            state.rfqTradingEnabled = payload.tradingEnabled;
            state.rfqViewingEnabled = payload.viewingEnabled;
        },
        setRfqFavoriteInstruments(state, { payload }: PayloadAction<string[]>) {
            state.favoriteInstruments = payload;
        },
    },
});

type RfqSettingsApiResponse = {
    id: number;
    clientId: number;
    tradingEnabled: boolean;
    viewingEnabled: boolean;
};

type RfqUserSettingsApiResponse = {
    favoriteInstruments: string[];
};

export function* loadRfqSettingsSaga() {
    try {
        yield put(
            setIsLoadingApp({
                key: "loadRfqSettingsSaga",
                isLoading: true,
            }),
        );

        const rfqSettings: RfqSettingsApiResponse = yield call(post, "rfq/getSettings", null);
        yield put(rfqSettingSlice.actions.setRfqSettings(rfqSettings));

        if (rfqSettings.tradingEnabled) {
            const userId: number = yield select((state: IRootState) => state.authentication.userId);
            const rfqFavoriteInstruments: RfqUserSettingsApiResponse = yield call(
                post,
                "rfq/getUserSettings",
                { userId },
            );
            yield put(
                rfqSettingSlice.actions.setRfqFavoriteInstruments(
                    rfqFavoriteInstruments.favoriteInstruments,
                ),
            );
        }
    } catch (e) {
        console.error(e);
    } finally {
        yield put(
            setIsLoadingApp({
                key: "loadRfqSettingsSaga",
                isLoading: false,
            }),
        );
    }
}

function* sagaSaveRfqFavoriteInstruments() {
    const userId: number = yield select((state: IRootState) => state.authentication.userId);
    const rfqFavoriteInstruments: string[] = yield select(
        (state: IRootState) => state.rfqSettings.favoriteInstruments,
    );

    const requestBody = {
        userId,
        favoriteInstruments: rfqFavoriteInstruments,
    };

    try {
        yield call(post, "rfq/setUserSettings", requestBody);
    } catch {
        yield put(
            createNotification({
                type: "error",
                content: "Error save rfq favorite instruments",
            }),
        );
    }
}

export function* rfqSettingsSaga() {
    yield takeEvery(
        rfqSettingSlice.actions.setRfqFavoriteInstruments,
        sagaSaveRfqFavoriteInstruments,
    );
}
