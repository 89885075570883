import {
    Flex,
    IconButton,
    Icons,
    InfiniteTable,
    PSmall,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { AssetInfo } from "components/AssetInfo";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { useMemo } from "react";
import styled from "styled-components";
import { fmt, toLongDateString } from "utils/format";
import { Status } from "../Status";
import { Transaction } from "../types";

interface TransactionsTableProps {
    data: Transaction[];
    onRemoveTransactionClick: (transaction: Transaction) => void;
    onSendDepositClick: (transaction: Transaction) => void;
    isLoading: boolean;
}

const FeePayer = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

const TYPE_TITLES = {
    deposit: "Deposit",
    withdrawal: "Withdrawal",
};

export const PAYER_TITLES = {
    recipient: "by Recipient",
    sender: "by Sender",
};

export const TransactionsTable = ({
    data,
    onRemoveTransactionClick,
    onSendDepositClick,
    isLoading,
}: TransactionsTableProps) => {
    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<Transaction>();

        return [
            columnHelper.accessor((row) => row.type, {
                id: "type",
                cell: (type) => {
                    const value = type.getValue();

                    return TYPE_TITLES[value];
                },
                header: "Type",
                meta: {
                    cellStyleProps: {
                        width: "92px",
                        padding: "0",
                    },
                    headerStyleProps: {
                        padding: "0 0 8px 0",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "asset",
                cell: (rowData) => {
                    const { asset, network } = rowData.getValue();

                    return <AssetInfo asset={asset} network={network} />;
                },
                header: "Asset",
                meta: {
                    cellStyleProps: {
                        width: "60px",
                    },
                },
            }),
            columnHelper.accessor((row) => row.amount, {
                id: "amount",
                cell: (amount) => {
                    const value = amount.getValue();

                    return fmt(value, {
                        significantDigits: 8,
                        type: "size64",
                    }).formattedValue;
                },
                header: "Amount",
                meta: {
                    cellStyleProps: {
                        width: "110px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.fee, {
                id: "fee",
                cell: (fee) => {
                    const value = fee.getValue();

                    return (
                        <VStack>
                            {value.size && (
                                <PSmall>
                                    {
                                        fmt(value.size, {
                                            significantDigits: 8,
                                            type: "size64",
                                        }).formattedValue
                                    }
                                </PSmall>
                            )}
                            <FeePayer>{PAYER_TITLES[value.payer]}</FeePayer>
                        </VStack>
                    );
                },
                header: "Fee",
                meta: {
                    cellStyleProps: {
                        width: "130px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.txId, {
                id: "txId",
                cell: (txId) => txId.getValue(),
                header: "TX ID",
            }),
            columnHelper.accessor((row) => row.comment, {
                id: "comment",
                cell: (comment) => comment.getValue(),
                header: "Comment",
            }),
            columnHelper.accessor((row) => row.created, {
                id: "created",
                cell: (created) => toLongDateString(created.getValue()),
                header: "Created",
                meta: {
                    cellStyleProps: {
                        width: "180px",
                    },
                },
            }),
            columnHelper.accessor((row) => row.status, {
                id: "status",
                cell: (status) => <Status value={status.getValue()} />,
                header: "Status",
                meta: {
                    cellStyleProps: {
                        width: "100px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.display({
                id: "actions",
                cell: (props) => {
                    const transaction = props.row.original;
                    const hasRemoveButton =
                        (transaction.status === "created" && transaction.direction === "fromYou") ||
                        transaction.status === "sent";
                    const hasSendButton =
                        transaction.status === "created" && transaction.direction === "fromYou";

                    return (
                        <Flex spacing={8} justifyContent="end">
                            {hasRemoveButton && (
                                <Tooltip content="Remove deposit">
                                    <IconButton
                                        variant="basic"
                                        Icon={Icons.Bin}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onRemoveTransactionClick(transaction);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasSendButton && (
                                <Tooltip content="Send deposit">
                                    <IconButton
                                        variant="primary"
                                        Icon={Icons.ArrowUp}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onSendDepositClick(transaction);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
                meta: {
                    cellStyleProps: {
                        width: "80px",
                        textAlign: "right",
                        padding: "0 0 0 12px",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                        padding: "0 0 8px 12px",
                    },
                },
            }),
        ];
    }, []);

    const tableOptions = {
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false,
    };

    return (
        <VStack paddingX={12}>
            <BottomFixTableContainer>
                <InfiniteTable tableOptions={tableOptions} isLoading={isLoading} />
            </BottomFixTableContainer>
        </VStack>
    );
};
