import { FC, useContext } from "react";
import styled from "styled-components";
import { MarketDepthSectionContext } from "../../context";
import { MarketDepthItem } from "../Item";

const Container = styled.div`
    flex: 1;
`;

type ListProps = {
    type: "bid" | "ask";
    list: [price: number | bigint, size: number | bigint, fmId?: number][];
};

export const List: FC<ListProps> = ({ type, list }) => {
    const { onMarketDepthItemClick } = useContext(MarketDepthSectionContext);

    const { sum, items } = list.reduce(
        (acc, [, size]) => {
            acc.sum += BigInt(size);
            acc.items.push(acc.sum);
            return acc;
        },
        { sum: BigInt(0), items: [] as bigint[] },
    );

    return (
        <Container>
            {list.map(([price, size, fmId], index) => (
                <MarketDepthItem
                    onClick={() => onMarketDepthItemClick(price)}
                    key={`${price}`}
                    price={price}
                    size={size}
                    fmId={fmId}
                    percent={Number((BigInt(items[index]) * 100n) / sum)}
                    type={type}
                    first={index === 0}
                />
            ))}
        </Container>
    );
};
